import { useTranslation } from '@pancakeswap/localization'
import {
  Box,
  Flex,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
} from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import { useEffect, useState, useCallback } from 'react'
import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import WalletModal, { WalletView } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'

const ButtonTopWallet = styled(ConnectWalletButton)`
  // background: linear-gradient(180deg, #7a436e, #7c364c);
  // background: linear-gradient(180deg, #6e7350, #4e7051);
  background: linear-gradient(180deg, #30a75f, #30a75f);
  border-radius: 2em;
  width:190px;
  height:32px;
  padding: unset;
  @media (max-width: 767px) {
    
  & {
    width:100px;
  }
}
`

const UserMenuItems = () => {
  const { t } = useTranslation()
  const { isWrongNetwork } = useActiveChainId()
  const { logout } = useAuth()
  const { hasPendingTransactions } = usePendingTransactions()
  // const { isInitialized, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)

  const onClickWalletMenu = useCallback((): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }, [isWrongNetwork, onPresentWalletModal, onPresentWrongNetworkModal])

  return (
    <>
      <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
      <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
        {t('Recent Transactions')}
        {hasPendingTransactions && <RefreshIcon spin />}
      </UserMenuItem>
      <UserMenuDivider />
      {/* <NextLink as="a" href={`/profile/${account?.toLowerCase()}`} passHref>
        <UserMenuItem disabled={isWrongNetwork || chainId !== ChainId.BSC}>
          {t('Your NFTs')}
        </UserMenuItem>
      </NextLink>
      <ProfileUserMenuItem
        isLoading={isLoading}
        hasProfile={hasProfile}
        disabled={isWrongNetwork || chainId !== ChainId.BSC}
      />
      <UserMenuDivider /> */}
      <UserMenuItem as="button" onClick={logout}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {t('Disconnect')}
          <LogoutIcon />
        </Flex>
      </UserMenuItem>
    </>
  )
}

const UserMenu = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { isWrongNetwork } = useActiveChainId()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const { profile } = useProfile()
  const avatarSrc = profile?.nft?.image?.thumbnail
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  if (account) {
    if (isWrongNetwork) {
      return (
        <Box height="50%">
          <UIKitUserMenu text={t('Network')} variant="danger">
            {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
          </UIKitUserMenu>
        </Box>
      )
    }
    return (
      <Box height="50%">
        <UIKitUserMenu account={account} avatarSrc={avatarSrc} text={userMenuText} variant={userMenuVariable}>
          {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
        </UIKitUserMenu>
      </Box>
    )
  }

  return (
    <ButtonTopWallet scale="xs">
      <Box display={['none', , , 'block']}>
        <div style={{
          fontFamily: 'Riffic Free',
          fontSize: '14px',
        }}>
          <Trans>Connect Wallet</Trans>
        </div>
      </Box>
      <Box display={['block', , , 'none']}>
        <div style={{
          fontFamily: 'Riffic Free',
          fontSize: '14px',
        }}>
          <Trans>Connect</Trans>
        </div>
      </Box>
    </ButtonTopWallet>
  )
}

export default UserMenu
