import { ChainId } from '@pancakeswap/sdk'
import memoize from 'lodash/memoize'
import invert from 'lodash/invert'

export const SUPPORTED_CHAINS = [
  ChainId.ETHEREUM, ChainId.PULSECHAIN
]

// get url and check if there are localhost word in it
const isLocalhost = false;
if (isLocalhost) {
  SUPPORTED_CHAINS.push(ChainId.SEPOLIA, ChainId.TESTNET, ChainId.PULSECHAINV4)
}

export const CHAIN_QUERY_NAME = {
  [ChainId.ETHEREUM]: 'eth',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.BSC]: 'bsc',
  [ChainId.PULSECHAIN]: 'pulsechain',
  [ChainId.PULSECHAINV4]: 'pulsechain_testnet',
  [ChainId.SEPOLIA]: 'sepolia',
  [ChainId.TESTNET]: 'testnet',
} satisfies Record<ChainId, string>

const CHAIN_QUERY_NAME_TO_ID = invert(CHAIN_QUERY_NAME)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName] ? +CHAIN_QUERY_NAME_TO_ID[chainName] : undefined
})
