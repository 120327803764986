import { ChainConfig, ChainId, Token } from '@pancakeswap/sdk'
import { isStableFarm, SerializedFarmConfig } from '@pancakeswap/farms'
import { getFarms } from './common'

let logged = false

export const getFarmConfig = async (chainId: ChainId) => {
  try {
    return getFarms(chainId).filter(
      (f: SerializedFarmConfig) => f.pid !== null,
    )
  } catch (error) {
    if (!logged) {
      console.error('Cannot get farm config', error, chainId)
      logged = true
    }
    return []
  }
}

export const getStableConfig = async (chainId: ChainId) => {
  try {
    const farms = getFarms(chainId)

    return farms.filter(isStableFarm)
  } catch (error) {
    if (!logged) {
      console.error('Cannot get stable farm config', error, chainId)
      logged = true
    }
    return []
  }
}
