import { BinanceWalletConnector } from '@pancakeswap/wagmi/connectors/binanceWallet'
import { BloctoConnector } from '@pancakeswap/wagmi/connectors/blocto'
import { TrustWalletConnector } from '@pancakeswap/wagmi/connectors/trustWallet'
import { mainnet, sepolia, foundry } from 'wagmi/chains'
// import { sepolia } from 'wagmi/chains'
import { Chain, configureChains, createClient } from 'wagmi'
import memoize from 'lodash/memoize'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { LedgerConnector } from 'wagmi/connectors/ledger'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { SUPPORTED_CHAINS } from 'config/chains'
import { window } from 'global/window'
import { SafeConnector } from './safeConnector'

const localnet = { ...mainnet, ...foundry, name: 'Hardhat', testnet: true }

const pulsechain = {
  id: 369,
  name: 'Pulsechain',
  network: 'pulsechain',
  nativeCurrency: { name: 'Pulse', symbol: 'PLS', decimals: 18 },
  rpcUrls: {
    ninerpc: {
      http: ['https://rpc-pulsechain.g4mm4.io'],
    },
    default: {
      http: ['https://rpc-pulsechain.g4mm4.io'],
    },
  },
  blockExplorers: {
    ninescan: { name: 'Pulsescan', url: 'https://scan.9inch.io/#' },
    default: { name: 'Pulsescan', url: 'https://scan.9inch.io/#' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 7654707,
    },
  },
} as Chain

const pulsechainTestnet = {
  id: 943,
  name: 'Pulsechain v4',
  network: 'pulsechain',
  nativeCurrency: { name: 'Pulse', symbol: 'tPLS', decimals: 18 },
  rpcUrls: {
    ninerpc: {
      http: ['https://rpc.v4.testnet.pulsechain.com'],
    },
    default: {
      http: ['https://rpc.v4.testnet.pulsechain.com'],
    },
  },
  blockExplorers: {
    ninescan: { name: 'Pulsescan', url: 'https://scan.v4.testnet.pulsechain.com' },
    default: { name: 'Pulsescan', url: 'https://scan.v4.testnet.pulsechain.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 7654707,
    },
  },
} as Chain

sepolia.rpcUrls = {
  default: {
    http: ['https://ethereum-sepolia.publicnode.com'],
  },
}

mainnet.rpcUrls = {
  default: {
    http: ['https://eth-mainnet.nodereal.io/v1/aa80dbecf485481787f90487d36e01a1'],
  },
}

// const CHAINS = [ Number(ChainId.ETHEREUM)==1 ? mainnet : localnet ]
// const CHAINS = [ mainnet, goerli, sepolia, localnet, polygonMumbai, pulsechain ].filter(c => c.id===ChainDefaultId)
const CHAINS = [mainnet, pulsechain, sepolia, pulsechainTestnet, localnet].filter((chain) =>
  SUPPORTED_CHAINS.includes(chain.id),
)

// const getNodeRealUrl = (networkName: string) => {
//   let host = null

//   switch (networkName) {
//     case 'homestead':
//       if (process.env.NEXT_PUBLIC_NODE_REAL_API_ETH) {
//         host = `eth-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_ETH}`
//       }
//       break
//     case 'goerli':
//       if (process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI) {
//         host = `eth-goerli.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI}`
//       }
//       break
//     default:
//       host = null
//   }

//   if (!host) {
//     return null
//   }

//   const url = `https://${host}`
//   return {
//     http: url,
//     webSocket: url.replace(/^http/i, 'wss').replace('.nodereal.io/v1', '.nodereal.io/ws/v1'),
//   }
// }

export const { provider, chains } = configureChains(CHAINS, [
  jsonRpcProvider({
    rpc: (chain) => {
      // let url = chain.id === 1 ? "https://rpc.ankr.com/eth"
      //   : chain.id === 369 ?
      //     "https://rpc-pulsechain.g4mm4.io" : "https://rpc.v4.testnet.pulsechain.com"
      // if (window && window.location.href.search('localhost') === -1) {
      //   url = chain.rpcUrls.default.http[0]
      // }
      // return { http: url }
      return { http: chain.rpcUrls.default.http[0] }
    },
  }),
])

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'PancakeSwap',
    appLogoUrl: 'https://pancakeswap.com/logo.png',
  },
})

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: true,
  },
})

export const walletConnectNoQrCodeConnector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: false,
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

const bloctoConnector = new BloctoConnector({
  chains,
  options: {
    defaultChainId: 56,
    appId: 'e2f2f0cd-3ceb-4dec-b293-bb555f2ed5af',
  },
})

const ledgerConnector = new LedgerConnector({
  chains,
})

export const bscConnector = new BinanceWalletConnector({ chains })

export const trustWalletConnector = new TrustWalletConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const client = createClient({
  autoConnect: false,
  provider,
  connectors: [
    new SafeConnector({ chains }),
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    walletConnectConnector,
    bscConnector,
    bloctoConnector,
    ledgerConnector,
    trustWalletConnector,
  ],
})

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId))
export const isChainTestnet = memoize((chainId: number) => chains.find((c) => c.id === chainId)?.testnet)
