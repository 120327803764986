import { ChainId } from "@pancakeswap/sdk"
import { tokens } from "@pancakeswap/tokens"

export const MINIMUM_SEARCH_CHARACTERS = 2

export const WEEKS_IN_YEAR = 52.1429

export const TOTAL_FEE = 0.0029
export const LP_HOLDERS_FEE = 0.0022
export const TREASURY_FEE = 0.0006
export const BUYBACK_FEE = 0.0001

export const PCS_V2_START = 1619136000 // April 23, 2021, 12:00:00 AM
export const PCS_ETH_START = 1698364019 // Sep 23, 2022, 02:33:47 AM
export const PCS_GOERLI_START = 1679427696
export const PCS_SEPOLIA_START = 1681663044
export const PCS_MUMBAI_START = 1681897193
export const PCS_PULSECHAIN_START = 1698393775
export const ONE_DAY_UNIX = 86400 // 24h * 60m * 60s
export const ONE_HOUR_SECONDS = 3600

export const ITEMS_PER_INFO_TABLE_PAGE = 10

// These tokens are either incorrectly priced or have some other issues that spoil the query data
// None of them present any interest as they have almost 0 daily trade volume

export const BSC_TOKEN_WHITELIST = []
export const TOKEN_BLACKLIST = [
  // These ones are copied from v1 info
  '0x495c7f3a713870f68f8b418b355c085dfdc412c3',
  '0xc3761eb917cd790b30dad99f6cc5b4ff93c4f9ea',
  '0xe31debd7abff90b06bca21010dd860d8701fd901',
  '0xfc989fbb6b3024de5ca0144dc23c18a063942ac1',
  '0xe40fc6ff5f2895b44268fd2e1a421e07f567e007',
  '0xfd158609228b43aa380140b46fff3cdf9ad315de',
  '0xc00af6212fcf0e6fd3143e692ccd4191dc308bea',
  '0x205969b3ad459f7eba0dee07231a6357183d3fb6',
  '0x0bd67d358636fd7b0597724aa4f20beedbf3073a',
  '0xedf5d2a561e8a3cb5a846fbce24d2ccd88f50075',
  '0x702b0789a3d4dade1688a0c8b7d944e5ba80fc30',
  '0x041929a760d7049edaef0db246fa76ec975e90cc',
  '0xba098df8c6409669f5e6ec971ac02cd5982ac108',
  '0x1bbed115afe9e8d6e9255f18ef10d43ce6608d94',
  '0xe99512305bf42745fae78003428dcaf662afb35d',
  '0xbE609EAcbFca10F6E5504D39E3B113F808389056',
  '0x847daf9dfdc22d5c61c4a857ec8733ef5950e82e',
  '0xdbf8913dfe14536c0dae5dd06805afb2731f7e7b',
  // These ones are newly found
  '0xF1D50dB2C40b63D2c598e2A808d1871a40b1E653',
  '0x4269e4090ff9dfc99d8846eb0d42e67f01c3ac8b',
]

export const ETH_TOKEN_BLACKLIST = ['0x72b169ad8af6c4fb53056b6a2a85602ad6863864']
export const ETH_TOKEN_WHITELIST = [
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  '0xdac17f958d2ee523a2206206994597c13d831ec7',
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  '0x993864e43caa7f7f12953ad6feb1d1ca635b875f',
]

export const GOERLI_TOKEN_BLACKLIST = ['0x72b169ad8af6c4fb53056b6a2a85602ad6863864']
export const GOERLI_TOKEN_WHITELIST = [
  '0x65f6e06c324c7c167576756a365e221bc0657816',
  '0xab738f29e55df25d69006aaefb638307b9b0ed3e',
]

export const SEPOLIA_TOKEN_BLACKLIST = [tokens[ChainId.SEPOLIA].usdc.address]
export const SEPOLIA_TOKEN_WHITELIST = []

export const PULSECHAIN_TOKEN_BLACKLIST = [tokens[ChainId.PULSECHAIN].dai.address]
export const PULSECHAIN_TOKEN_WHITELIST = []

export const MUMBAI_TOKEN_BLACKLIST = ['0x95cbbf1fffd16b2c4574bed4becc81510fd057fd']
export const MUMNAI_TOKEN_WHITELIST = [
  '0x1385d8b0cb0375f1e9896825bc0791b0a2e2a95f', // NineInch
  '0x6c2b4e09466761657e9c1e3865e0406a09c1a5c5', // BBC
  '0x789ae61512dfecd741b41ae583ced8df1b5be24f', // HDRN
  '0x4bd4687e7ccc56c62a5a2aec1a201b32492d1b04', // TEXAN
  '0x30cc22681166c0207e2fce48e9ecfd604c6b059c', // ICSA
  '0x91c3bde2ba0b62fcf6aab37e2a93e0833d255678', // MAXI
  '0x3c1a25c4ddef79d972f316b3662965c8980e787b', // TEAM
  '0x9bb99efd093f1aa04459200977ef3ca00be6efb4', // DECI
  '0xbc8815d5e1ce642e6f6a47d64421cc37bb5d595c', // LUCKY
  '0xa025fcf8ef1af59889d3bfc1be1f166b62ee100a', // TRIO
  '0x3956d5304216a718a6f73a0449564a6a832c12bf', // POLY
  '0x6fe91be045cdb883efe80baa0f9721fc0974440e', // BASE
  '0x1c3f47d0f8b7098e548adce6ecf5e39b14350585', // PLSD
  '0x37c0395d627f1ff8c729eb7723282e65402ffff9', // PLSB
  '0x3e26e1850ade99fd5919654f8321bf973f3e43be', // ASIC
  '0x4d321e44e57bed781f96936413bbcd54c8f5d255', // PP
  '0x32cae85d0ba8f7a157b3b806c4873b37f1f86944', // MORE
  '0x755392edda7be30a4ccd5fa7844ed4b071512735', // PLD
  '0x1da62928bf9870c898f2a15cd9c6bfc261558605', // PZEN
  '0x73762d81d0eb5c1a502f2b9f974faeb1691fdf56', // COM
  '0xc96e8301ca14418c36f4cd17bcac13f4977520ac', // DBI
  '0x74dff5d784ef352bdebcde68e9b1923395725da6', // XEN
  '0x4f77d5060d84d5245e74d7601e4d980af9ccc75d', // HEX
  '0x2eeba97928d87efa50dac8800e8a25801367cca8', // aPhiat
  '0x54c415f887905504347c3340564d1984f96b59f7', // DXN
  '0x6c0325fe027d20eeca49dbbaa4692fca8f835b7a', // XLON
]
