import { Token } from '@pancakeswap/sdk'

interface WarningTokenList {
  [key: string]: Token
}

const SwapWarningTokens = <WarningTokenList>{
}

export default SwapWarningTokens
