import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  MoreIcon,
  DropdownMenuItems,
  GithubIcon,
  RocketIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'


export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('TRADE'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: true,
        items: [
          {
            label: t('SWAP'),
            href: '/swap',
          },
          {
            label: t('LIQUIDITY'),
            href: '/liquidity',
          },
          {
            label: t('DCA'),
            href: '/dca',
          },
          {
            label: t('LIMIT ORDER'),
            href: '/limit-orders',
            supportChainIds: SUPPORT_ONLY_BSC,
            image: '/images/decorations/3d-coin.png',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('EARN'),
        href: '/farms',
        icon: EarnIcon,
        fillIcon: EarnFillIcon,
        image: '/images/decorations/pe2.png',
        items: [
          {
            label: t('STAKING'),
            href: '/pools',
            supportChainIds: SUPPORT_ONLY_BSC,
          },
          {
            label: t('FARMS'),
            href: '/farms',
          },
          {
            label: t('pFARMS'),
            href: '/prc20farms',
          },
          {
            label: t('BBC POOLS'),
            href: '/coinpools',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: "TOOLS",
        href: '/launchpad',
        icon: RocketIcon,
        items: [
          {
            label: t('DEPLOY TOKEN'),
            href: '/launchpad',
          },
          {
            label: t('AIRDROP'),
            href: '/airdrop',
          },
          {
            label: t('BURN'),
            href: '/burn',
          },
          {
            label: t('BRIDGE'),
            href: 'https://bridge.pulsechain.com',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('SCAN'),
            href: 'https://scan.9inch.io/#/',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('STATS'),
            href: '/info',
            icon: TrophyIcon,
            fillIcon: TrophyFillIcon,
            image: '/images/ifos/ifo-bunny.png',
            showItemsOnMobile: false,
            items: [
              // {
              //   label: t('FARMS'),
              //   href: '/farms',
              // },
              // {
              //   label: t('POOLS'),
              //   href: '/pools',
              //   supportChainIds: SUPPORT_ONLY_BSC,
              // },
            ].map((item) => addMenuItemSupported(item, chainId)),
          },

        ].map((item) => addMenuItemSupported(item, chainId)),
      },

      // {
      //   label: 'SERVICES',
      //   icon: MoreIcon,
      //   hideSubNav: true,
      //   items: [
      //     {
      //       label: t('HEX'),
      //       href: 'https://hex.9inch.io/#/',
      //       type: DropdownMenuItemType.EXTERNAL_LINK,
      //     },
      //     {
      //       label: t('PulseX'),
      //       href: 'https://pulsex.9inch.io/',
      //       type: DropdownMenuItemType.EXTERNAL_LINK,

      //     }
      //   ].map((item) => addMenuItemSupported(item, chainId)),
      // },
      {
        label: '',
        icon: MoreIcon,
        hideSubNav: true,
        items: [
          {
            label: t('9INCH'),
            href: 'https://t.me/dex9inch',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('COINHUB'),
            href: 'https://t.me/coinhub_9inch',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('WEB'),
            href: 'https://9inch.io',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('VOTING'),
            href: '/voting',
            image: '/images/voting/voting-bunny.png',
          },
          {
            label: t('HEX'),
            href: 'https://hex.9inch.io/#/',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('PulseX'),
            href: 'https://pulsex.9inch.io/',
            type: DropdownMenuItemType.EXTERNAL_LINK,

          }
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
