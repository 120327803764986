import { multicallAddresses } from '@pancakeswap/multicall'
import { mapChainConfig } from '@pancakeswap/sdk'

export default {
  masterChef: mapChainConfig("MasterChef"),
  masterChefV1: {
  },
  sousChef: {
  },
  orderBook: mapChainConfig("OrderBook"),
  lotteryV2: {
  },
  multiCall: multicallAddresses,
  pancakeProfile: {
  },
  pancakeBunnies: {
  },
  bunnyFactory: {
  },
  claimRefund: {
  },
  pointCenterIfo: {
  },
  bunnySpecial: {
  },
  tradingCompetitionEaster: {
  },
  tradingCompetitionFanToken: {
  },
  tradingCompetitionMobox: {
  },
  tradingCompetitionMoD: {
  },
  easterNft: {
  },
  cakeVault: mapChainConfig("CakeVault"),
  cakeFlexibleSideVault: mapChainConfig("CakeFlexibleVault"),
  predictionsBNB: {
  },
  predictionsCAKE: {
  },
  chainlinkOracleBNB: {
  },
  chainlinkOracleCAKE: {
  },
  predictionsV1: {
  },
  bunnySpecialCakeVault: {
  },
  bunnySpecialPrediction: {
  },
  bunnySpecialLottery: {
  },
  bunnySpecialXmas: {
  },
  farmAuction: {
  },
  AnniversaryAchievement: {
  },
  nftMarket: {
  },
  nftSale: {
  },
  pancakeSquad: {
  },
  potteryDraw: {
  },
  zap: {
  },
  stableSwapNativeHelper: {
  },
  iCake: {
  },
  bCakeFarmBooster: {
  },
  bCakeFarmBoosterProxyFactory: {
  },
  nonBscVault: {
    ...mapChainConfig("MasterChef"),
  },
  crossFarmingSender: {
  },
  crossFarmingReceiver: {
  },
  mmLinkedPool: {
  },
}
