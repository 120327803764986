import BigNumber from 'bignumber.js'
import { ChainId } from '@pancakeswap/sdk'
import { IsLocked, SerializedLockedVaultUser, SerializedVaultUser } from 'state/types'
import { getCakeFlexibleSideVaultAddress, getCakeVaultAddress } from 'utils/addressHelpers'
import cakeVaultAbi from 'config/abi/cakeVaultV2.json'
import cakeFlexibleVaultAbi from 'config/abi/cakeFlexibleSideVaultV2.json'
import { multicallv2, multicallv3 } from 'utils/multicall'
import { livePools } from 'config/constants/pools'


export const fetchUserVaultDatas = async (account: string, chainId: ChainId) => {
  const pools = livePools.filter(pool => !!pool.vaultKey && !!pool.contractAddress[chainId])
  try {
    const calls = []
    pools.map(pool => {
      const vaultAddress = pool.contractAddress[chainId]
      if(IsLocked(pool.vaultKey)) {
        calls.push(...['userInfo', 'getProfit', 'calculatePerformanceFee', 'calculateOverdueFee'].map((method) => ({
          abi: cakeVaultAbi,
          address: vaultAddress,
          name: method,
          params: [account],
        })))
      } else {
        calls.push(...['userInfo', 'getProfit'].map((method) => ({
          abi: cakeFlexibleVaultAbi,
          address: vaultAddress,
          name: method,
          params: [account],
        })))
      }
      return undefined
    })

    const result = await multicallv3({
      allowFailure: true,
      calls,
      chainId
    })

    return pools.reduce((data, pool) => {
      if(IsLocked(pool.vaultKey)) {
        const [userContractResponse, [profit], [currentPerformanceFee], [currentOverdueFee]] = result.splice(0, 4)    
        return {
          ...data,
          [pool.vaultKey]: {
            isLoading: false,
            userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
            lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
            lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
            lastUserActionAmount: new BigNumber(userContractResponse.lastUserActionAmount.toString()).toJSON(),
            userBoostedShare: new BigNumber(userContractResponse.userBoostedShare.toString()).toJSON(),
            locked: userContractResponse.locked,
            lockEndTime: userContractResponse.lockEndTime.toString(),
            lockStartTime: userContractResponse.lockStartTime.toString(),
            lockedAmount: new BigNumber(userContractResponse.lockedAmount.toString()).toJSON(),
            currentPerformanceFee: new BigNumber(currentPerformanceFee.toString()).toJSON(),
            currentOverdueFee: new BigNumber(currentOverdueFee.toString()).toJSON(),
            pendingProfit: new BigNumber(profit.toString()).toJSON(),
          }
        }
      }
      const [userContractResponse, profit] = result.splice(0, 2)
      return {
        ...data,
        [pool.vaultKey]: {
          isLoading: false,
          userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
          lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
          lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
          lastUserActionAmount: new BigNumber(userContractResponse.lastUserActionAmount.toString()).toJSON(),
          pendingProfit: new BigNumber(profit ? profit[0].toString() : '0').toJSON(),
        }
      }
    }, {})
  } catch (error) {
    console.error(error)
    return pools.reduce((data, pool) => {
      if(IsLocked(pool.vaultKey)) {
        return {
          ...data,
          [pool.vaultKey]: {
            isLoading: true,
            userShares: null,
            lastDepositedTime: null,
            lastUserActionTime: null,
            lastUserActionAmount: null,
            userBoostedShare: null,
            lockEndTime: null,
            lockStartTime: null,
            locked: null,
            lockedAmount: null,
            currentPerformanceFee: null,
            currentOverdueFee: null,
            pendingProfit: null
          }
        }
      }
      return {
        ...data,
        [pool.vaultKey]: {
          isLoading: true,
          userShares: null,
          lastDepositedTime: null,
          lastUserActionTime: null,
          lastUserActionAmount: null,
          pendingProfit: null
        }
      }
    }, {})
  }
}

export const fetchVaultUser = async (account: string, chainId: ChainId, contractAddress?: string): Promise<SerializedLockedVaultUser> => {
  try {
    const calls = ['userInfo', 'getProfit', 'calculatePerformanceFee', 'calculateOverdueFee'].map((method) => ({
      address: contractAddress ?? getCakeVaultAddress(chainId),
      name: method,
      params: [account],
    }))

    const [userContractResponse, [profit], [currentPerformanceFee], [currentOverdueFee]] = await multicallv2({
      abi: cakeVaultAbi,
      calls,
      chainId
    })

    return {
      isLoading: false,
      userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
      lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
      lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
      lastUserActionAmount: new BigNumber(userContractResponse.lastUserActionAmount.toString()).toJSON(),
      userBoostedShare: new BigNumber(userContractResponse.userBoostedShare.toString()).toJSON(),
      locked: userContractResponse.locked,
      lockEndTime: userContractResponse.lockEndTime.toString(),
      lockStartTime: userContractResponse.lockStartTime.toString(),
      lockedAmount: new BigNumber(userContractResponse.lockedAmount.toString()).toJSON(),
      currentPerformanceFee: new BigNumber(currentPerformanceFee.toString()).toJSON(),
      currentOverdueFee: new BigNumber(currentOverdueFee.toString()).toJSON(),
      pendingProfit: new BigNumber(profit.toString()).toJSON(),
    }
  } catch (error) {
    return {
      isLoading: true,
      userShares: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
      lastUserActionAmount: null,
      userBoostedShare: null,
      lockEndTime: null,
      lockStartTime: null,
      locked: null,
      lockedAmount: null,
      currentPerformanceFee: null,
      currentOverdueFee: null,
      pendingProfit: null
    }
  }
}

export const fetchFlexibleSideVaultUser = async (account: string, chainId: ChainId, contractAddress?: string): Promise<SerializedVaultUser> => {
  try {
    const calls = ['userInfo', 'getProfit'].map((method) => ({
      address: contractAddress ?? getCakeFlexibleSideVaultAddress(chainId),
      name: method,
      params: [account],
    }))

    const [userContractResponse, [profit]] = await multicallv2({
      abi: cakeFlexibleVaultAbi,
      calls,
      chainId
    })
    return {
      isLoading: false,
      userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
      lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
      lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
      lastUserActionAmount: new BigNumber(userContractResponse.lastUserActionAmount.toString()).toJSON(),
      pendingProfit: new BigNumber(profit.toString()).toJSON(),
    }
  } catch (error) {
    return {
      isLoading: true,
      userShares: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
      lastUserActionAmount: null,
      pendingProfit: null
    }
  }
}
