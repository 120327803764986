import { ChainConfig, ChainId, NATIVE } from "@pancakeswap/sdk"
import { tokens } from '@pancakeswap/tokens'
import { SerializedFarmConfig } from '@pancakeswap/farms'

export const CAKE_BNB_LP_MAINNET = '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0'

export const totalFarms = (chainId: ChainId) => [
  // ...['cake', 'nineinch', 'hex', 'dbi', 'pp', 'pld', 'more', 'plsd', 'pepe', 'maxi'].map((token) => ({
  //   pid: ChainConfig[chainId].Pools[ChainConfig[chainId].PoolToken],
  //   lpSymbol: `ETH-${tokens[chainId][token].symbol} LP`,
  //   lpAddress: ChainConfig[chainId][`ETH_${tokens[chainId][token].symbol}`],
  //   token: tokens[chainId][token],
  //   quoteToken: tokens[chainId].weth,
  //   isSpecial: true
  // })),
  // ...['BBC-9INCH', 'ETH-9INCH', 'ETH-BBC', 'ETH-HEX', 'ETH-DBI', 'ETH-PP', 'ETH-PLD', 'ETH-HDRN', 'ETH-XEN', 'ETH-MORE', 'ETH-PLSD', 'ETH-IM'].map(lp => {
  ...Object.entries(ChainConfig[chainId].Pools).map(([lpAddress, pid]) => {
    const lp = Object.entries(ChainConfig[chainId]).filter(([, address]) => address === lpAddress).map(([pair,]) => pair)?.[0]
    const names = lp.split('_')
    const name2token = (name: string) => {
      if (name === 'BBC') return 'cake'
      if (name === '9INCH') return 'nineinch'
      if (name === 'ETH' || name === 'PLS') return 'weth'
      return name.toLowerCase()
    }
    const token = tokens[chainId][name2token(names[1])]
    const quoteToken = tokens[chainId][name2token(names[0])]
    return {
      pid,
      lpSymbol: `${lp.replace('_', '-').replace(/\bETH\b/, NATIVE[chainId].symbol)} LP`,
      lpAddress,
      token,
      quoteToken,
    }
  }),
]

export const getFarms = (chainId: ChainId) => {
  return totalFarms(chainId).filter((farm: any) => !farm.isSpecial).map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize })) as SerializedFarmConfig[]
}