import { ChainConfig, ChainId, ChainIdList, ERC20Token } from '@pancakeswap/sdk'

export const BUSD: Record<ChainId, ERC20Token> = ChainIdList.reduce((prev: any, chainId: ChainId) => {
  if(ChainConfig[chainId].BUSD)
    return { 
        ...prev, 
        [chainId]: new ERC20Token(chainId, ChainConfig[chainId].BUSD, 18, 'BUSD', 'Binance USD', ''),
    }
  return prev
}, {})

export const DAI: Record<ChainId, ERC20Token> = ChainIdList.reduce((prev: any, chainId: ChainId) => {
  if(ChainConfig[chainId].DAI)
    return { 
        ...prev, 
        [chainId]: new ERC20Token(chainId, ChainConfig[chainId].DAI, 18, 'DAI', 'DAI', ''),
    }
  return prev
}, {})

export const CAKE: Record<ChainId, ERC20Token> = ChainIdList.reduce((prev: any, chainId: ChainId) => {
  return { 
      ...prev, 
      [chainId]: new ERC20Token(chainId, ChainConfig[chainId].BBC, 18, 'BBC', 'Big Bonus Coin', ''),
  }
}, {})

export const NINEINCH: Record<ChainId, ERC20Token> = ChainIdList.reduce((prev: any, chainId: ChainId) => {
  return { 
      ...prev, 
      [chainId]: new ERC20Token(chainId, ChainConfig[chainId].NineInch, 18, '9INCH', '9INCH', ''),
  }
}, {})

export const EARN = CAKE

export const USDC: Record<ChainId, ERC20Token> = ChainIdList.reduce((prev: any, chainId: ChainId) => {
  return { 
      ...prev, 
      [chainId]: new ERC20Token(chainId, ChainConfig[chainId].USDC, 6, 'USDC', 'USD Coin', ''),
  }
}, {})

export const USDT: Record<ChainId, ERC20Token> = ChainIdList.reduce((prev: any, chainId: ChainId) => {
  return { 
      ...prev, 
      [chainId]: new ERC20Token(chainId, ChainConfig[chainId].USDC, 6, 'USDT', 'USDT', ''),
  }
}, {})