import { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Currency } from '@pancakeswap/sdk';
import { BottomDrawer, Flex, useMatchBreakpoints } from '@pancakeswap/uikit';
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory';
import { AppBody } from 'components/App';
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers';
import { currencyId } from 'utils/currencyId';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Page from 'components/Layout/Page';
import TopTokenMovers from './components/TopTokenMovers'
import { useCurrency } from '../../hooks/Tokens';
import { Field } from '../../state/swap/actions';
import { useSingleTokenSwapInfo, useSwapState } from '../../state/swap/hooks';
import PriceChartContainer from './components/Chart/PriceChartContainer';
import useWarningImport from './hooks/useWarningImport';
import { SmartSwapForm } from './SmartSwap';
import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles';
import { SwapFeaturesContext } from './SwapFeaturesContext';



const queryClient = new QueryClient();

export default function Swap() {
  const { isDesktop } = useMatchBreakpoints();
  const { 
    isChartExpanded, 
    isChartDisplayed, 
    setIsChartDisplayed, 
    setIsChartExpanded, 
    isChartSupported 
  } = useContext(SwapFeaturesContext);

  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();

  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);

  const currencies = {
    [Field.INPUT]: inputCurrency,
    [Field.OUTPUT]: outputCurrency,
  };

  const singleTokenPrice = useSingleTokenSwapInfo(inputCurrencyId, inputCurrency, outputCurrencyId, outputCurrency);
  const warningSwapHandler = useWarningImport();
  const { onCurrencySelection } = useSwapActionHandlers();

  const handleOutputSelect = useCallback(
    (newCurrencyOutput: Currency) => {
      onCurrencySelection(Field.OUTPUT, newCurrencyOutput);
      warningSwapHandler(newCurrencyOutput);

      const newCurrencyOutputId = currencyId(newCurrencyOutput);
      if (newCurrencyOutputId === inputCurrencyId) {
        replaceBrowserHistory('inputCurrency', outputCurrencyId);
      }
      replaceBrowserHistory('outputCurrency', newCurrencyOutputId);
    },
    [inputCurrencyId, outputCurrencyId, onCurrencySelection, warningSwapHandler]
  );

 
  return (
    <Page>
      <TopTokenMovers />
      <Flex width="100%" flexDirection="column" alignItems="center">
        <Flex flexDirection={isDesktop ? "row" : "column"} justifyContent="center" alignItems="center">
          <StyledSwapContainer $isChartExpanded={isChartExpanded} style={!isDesktop ? {width: '100%'} : {}}>
            <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'} style={!isDesktop ? {width: '100%'} : {}}>
              <AppBody>
                <QueryClientProvider client={queryClient}>
                  <SmartSwapForm handleOutputSelect={handleOutputSelect} />
                </QueryClientProvider>
              </AppBody>
            </StyledInputCurrencyWrapper>
          </StyledSwapContainer>
        </Flex>
      </Flex>
    </Page>
  );
}
