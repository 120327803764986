import BigNumber from 'bignumber.js'
import { multicallv3 } from 'utils/multicall'
import cakeAbi from 'config/abi/cake.json'
import cakeVaultAbi from 'config/abi/cakeVaultV2.json'
import cakeFlexibleVaultAbi from 'config/abi/cakeFlexibleSideVaultV2.json'
import masterChefAbi from 'config/abi/masterchef.json'
import { getCakeVaultAddress, getCakeFlexibleSideVaultAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { CAKE } from '@pancakeswap/tokens'
import { livePools } from 'config/constants/pools'
import { IsCakeVault, IsLocked, Locked2Flexible } from 'state/types'
import { ChainId } from '@pancakeswap/sdk'
import { masterChefAddresses } from '@pancakeswap/farms/src/const'

export const fetchPublicVaultDatas = async (chainId: ChainId) => {
  const pools = livePools.filter(pool => !!pool.vaultKey && !!pool.contractAddress[chainId])
  try {
    const calls = []
    pools.map(pool => {
      const vaultAddress = pool.contractAddress[chainId]
      if(IsLocked(pool.vaultKey)) {
        calls.push({
          abi: masterChefAbi,
          address: masterChefAddresses[chainId],
          name: 'userInfo',
          params: [0, vaultAddress]
        })
        calls.push(...['getPricePerFullShare', 'calculateTotalPendingBBCRewards', 'totalShares', 'totalBoostDebt', 'totalLockedAmount', 'totalStakedAmount'].map((method) => ({
          abi: cakeVaultAbi,
          address: vaultAddress,
          name: method,
        })))
        calls.push({
          abi: cakeAbi,
          address: CAKE[chainId].address,
          name: 'balanceOf',
          params: [vaultAddress],
        })
      } else {
        calls.push(...['getPricePerFullShare', 'totalShares', !IsCakeVault(pool.vaultKey) ? 'totalStakedAmount' : 'totalShares'].map((method) => ({
          abi: cakeFlexibleVaultAbi,
          address: vaultAddress,
          name: method,
        })))
        calls.push({
          abi: cakeAbi,
          address: CAKE[chainId].address,
          name: 'balanceOf',
          params: [vaultAddress],
        })
      }
      return undefined
    })

    const result = await multicallv3({
      calls,
      allowFailure: true,
      chainId
    })

    return pools.reduce((data, pool) => {
      if(IsLocked(pool.vaultKey)) {
        const [poolInfo, [sharePrice], [rewards], [shares], [totalBoostDebt], totalLockedAmount, totalStakedAmount, [totalCakeInVault]] = result.splice(0, 8)
        const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
        const totalBoostAsBigNumber = totalBoostDebt ? new BigNumber(totalBoostDebt.toString()) : BIG_ZERO
        const pendingRewardsAsBigNumber = rewards ? new BigNumber(rewards.toString()) : BIG_ZERO
        const totalLockedAmountAsBigNumber = totalLockedAmount ? new BigNumber(totalLockedAmount[0].toString()) : BIG_ZERO
        const totalStakedAmountAsBigNumber = totalStakedAmount ? new BigNumber(totalStakedAmount[0].toString()) : BIG_ZERO
        const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
        return {
          ...data,
          [pool.vaultKey]: {
            isFinished: !poolInfo?.amount?.gt(0),
            totalShares: totalSharesAsBigNumber.toJSON(),
            totalBoostDebt: totalBoostAsBigNumber.toJSON(),
            totalLockedAmount: totalLockedAmountAsBigNumber.toJSON(),
            totalStakedAmount: totalStakedAmountAsBigNumber.toJSON(),
            pricePerFullShare: sharePriceAsBigNumber.toJSON(),
            pendingRewards: pendingRewardsAsBigNumber.toJSON(),
            totalCakeInVault: new BigNumber(totalCakeInVault.toString()).toJSON(),
          },
          [Locked2Flexible(pool.vaultKey)]: {
            isFinished: !poolInfo?.amount?.gt(0),
          }
        }
      }
      const [[sharePrice], [shares], [totalStakedAmount], [totalCakeInVault]] = result.splice(0, 4)    
      const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
      const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
      return {
        ...data,
        [pool.vaultKey]: {
          ...data[pool.vaultKey],
          totalShares: totalSharesAsBigNumber.toJSON(),
          pricePerFullShare: sharePriceAsBigNumber.toJSON(),
          totalStakedAmount: new BigNumber(!IsCakeVault(pool.vaultKey) ? totalStakedAmount.toString() : "0").toJSON(),
          totalCakeInVault: new BigNumber(totalCakeInVault.toString()).toJSON(),
        }
      }
    }, {})
  } catch (error) {
    return pools.reduce((data, pool) => {
      if(IsLocked(pool.vaultKey)) {
        return {
          ...data,
          [pool.vaultKey]: {
            totalShares: null,
            totalBoostDebt: null,
            totalLockedAmount: null,
            totalStakedAmount: null,
            pricePerFullShare: null,
            pendingRewards: null,
            totalCakeInVault: null,
          }
        }
      }
      return {
        ...data,
        [pool.vaultKey]: {
          totalShares: null,
          pricePerFullShare: null,
          totalStakedAmount: null,
          totalCakeInVault: null,
        }
      }
    }, {})
  }
}

export const fetchPublicVaultData = async (chainId, contractAddress = undefined) => {
  try {
    const vaultAddress = contractAddress ?? getCakeVaultAddress(chainId)
    const calls = ['getPricePerFullShare', 'calculateTotalPendingBBCRewards', 'totalShares', 'totalBoostDebt', 'totalLockedAmount', 'totalStakedAmount'].map((method) => ({
      abi: cakeVaultAbi,
      address: vaultAddress,
      name: method,
    }))

    const cakeBalanceOfCall = {
      abi: cakeAbi,
      address: CAKE[chainId].address,
      name: 'balanceOf',
      params: [vaultAddress],
    }

    const [[sharePrice], [rewards], [shares], [totalBoostDebt], totalLockedAmount, totalStakedAmount, [totalCakeInVault]] = await multicallv3({
      calls: [...calls, cakeBalanceOfCall],
      allowFailure: true,
      chainId
    })

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const totalBoostAsBigNumber = totalBoostDebt ? new BigNumber(totalBoostDebt.toString()) : BIG_ZERO
    const pendingRewardsAsBigNumber = rewards ? new BigNumber(rewards.toString()) : BIG_ZERO
    const totalLockedAmountAsBigNumber = totalLockedAmount ? new BigNumber(totalLockedAmount[0].toString()) : BIG_ZERO
    const totalStakedAmountAsBigNumber = totalStakedAmount ? new BigNumber(totalStakedAmount[0].toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      totalBoostDebt: totalBoostAsBigNumber.toJSON(),
      totalLockedAmount: totalLockedAmountAsBigNumber.toJSON(),
      totalStakedAmount: totalStakedAmountAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      pendingRewards: pendingRewardsAsBigNumber.toJSON(),
      totalCakeInVault: new BigNumber(totalCakeInVault.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      totalBoostDebt: null,
      totalLockedAmount: null,
      totalStakedAmount: null,
      pricePerFullShare: null,
      pendingRewards: null,
      totalCakeInVault: null,
    }
  }
}

export const fetchPublicFlexibleSideVaultData = async (chainId, contractAddress = undefined) => {
  try {
    const vaultCakeAddress = getCakeFlexibleSideVaultAddress(chainId)
    const vaultAddress = contractAddress ?? vaultCakeAddress
    const calls = ['getPricePerFullShare', 'totalShares', vaultAddress!==vaultCakeAddress ? 'totalStakedAmount' : 'totalShares'].map((method) => ({
      abi: cakeFlexibleVaultAbi,
      address: vaultAddress,
      name: method,
    }))

    const cakeBalanceOfCall = {
      abi: cakeAbi,
      address: CAKE[chainId].address,
      name: 'balanceOf',
      params: [vaultAddress],
    }

    const [[sharePrice], [shares], [totalStakedAmount], [totalCakeInVault]] = await multicallv3({
      calls: [...calls, cakeBalanceOfCall],
      allowFailure: true,
      chainId
    })

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalStakedAmount: new BigNumber(vaultAddress!==vaultCakeAddress ? totalStakedAmount.toString() : "0").toJSON(),
      totalCakeInVault: new BigNumber(totalCakeInVault.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      pricePerFullShare: null,
      totalStakedAmount: null,
      totalCakeInVault: null,
    }
  }
}

export const fetchVaultFees = async (chainId) => {
  const pools = livePools.filter(pool => !!pool.vaultKey && IsLocked(pool.vaultKey) && !!pool.contractAddress[chainId])
  try {
    const calls = []
    pools.map(pool => {
      const vaultAddress = pool.contractAddress[chainId]
      calls.push(...['performanceFee', 'withdrawFee', 'withdrawFeePeriod'].map((method) => ({
        abi: cakeVaultAbi,
        address: vaultAddress,
        name: method,
      })))
      return undefined
    })

    const result = await multicallv3({
      calls,
      allowFailure: true,
      chainId
    })

    return pools.reduce((data, pool) => {
      try {
        const [[performanceFee], [withdrawalFee], [withdrawalFeePeriod]] = result.splice(0, 3)
        return {
          ...data,
          [pool.vaultKey]: {
            performanceFee: performanceFee.toNumber(),
            withdrawalFee: withdrawalFee.toNumber(),
            withdrawalFeePeriod: withdrawalFeePeriod.toNumber(),
          },
          [Locked2Flexible(pool.vaultKey)]: {
            performanceFee: performanceFee.toNumber(),
            withdrawalFee: withdrawalFee.toNumber(),
            withdrawalFeePeriod: withdrawalFeePeriod.toNumber(),
          }
        }
      } catch(ex) {
        return {
          ...data,
          [pool.vaultKey]: {
            performanceFee: null,
            withdrawalFee: null,
            withdrawalFeePeriod: null,
          },
          [Locked2Flexible(pool.vaultKey)]: {
            performanceFee: null,
            withdrawalFee: null,
            withdrawalFeePeriod: null,
          }
        }
      }
    }, {})
  } catch (error) {
    return pools.reduce((data, pool) => {
      return {
        ...data,
        [pool.vaultKey]: {
          performanceFee: null,
          withdrawalFee: null,
          withdrawalFeePeriod: null,
        }
      }
    }, {})
  }
}

export default fetchPublicVaultData
