import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '@pancakeswap/localization';
import { findToken } from '@pancakeswap/tokens';
import { Box, Flex, Text, NextLinkFromReactRouter, useTooltip, copyText } from '@pancakeswap/uikit';
import { CurrencyLogo as ChainCurrencyLogo } from 'components/Logo';
import { multiChainId } from 'state/info/constant';
import { useAllTokenDataSWR, useGetChainName, useMultiChainPath } from 'state/info/hooks';
import { TokenData } from 'state/info/types';
import { formatAmount } from 'utils/formatInfoNumbers';
import Row from 'components/Layout/Row';
import { CurrencyLogo } from 'views/Info/components/CurrencyLogo';
import Percent from 'views/Info/components/Percent';
import { Card } from './components/Card'

const CardWrapper = styled(NextLinkFromReactRouter)`
  display: inline-block;
  min-width: 190px;
  margin-left: 16px;
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const TopMoverCard = styled(Box)`
  padding: 16px;
`;

const ScrollableRow = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding: 16px 0;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const DataCard = ({ tokenData }: { tokenData: TokenData }) => {
  const chainName = useGetChainName()
  const chainPath = useMultiChainPath()
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);

  const { targetRef, tooltip } = useTooltip("Copied to clipboard", {
    placement: "top",
    manualVisible: true,
    trigger: "hover",
  });

  const displayTooltip = () => {
    setIsTooltipDisplayed(true);
    setTimeout(() => {
      setIsTooltipDisplayed(false);
    }, 1000);
  };

  return (
    // <CardWrapper to={`/info${chainPath}/tokens/${tokenData.address}`}>
    <CardWrapper to="#" ref={targetRef} onClick={() => copyText(tokenData.address, displayTooltip)}
    >

      {isTooltipDisplayed && tooltip}
      <TopMoverCard>
        <Flex>
          <Box width="32px" height="32px">
            {/* wrapped in a box because of alignment issues between img and svg */}
            <CurrencyLogo address={tokenData.address} size="32px" chainName={chainName} />
          </Box>
          <Box ml="5px">
            <Row>
              <Text mr="5px">{tokenData.symbol}</Text>
              <Percent fontSize="14px" value={tokenData.priceUSDChange} />
            </Row>
          </Box>
        </Flex>
      </TopMoverCard>
    </CardWrapper>
  )
}

const TopTokenMovers: React.FC = () => {
  const allTokens = useAllTokenDataSWR();
  const { t } = useTranslation();

  const topPriceIncrease = useMemo(() => {
    return Object.values(allTokens)
      .sort((a, b) => {
        return a.data && b.data ? (Math.abs(a.data.priceUSDChange) > Math.abs(b.data.priceUSDChange) ? -1 : 1) : -1;
      })
      .slice(0, Math.min(20, Object.values(allTokens).length))
      .filter((d) => d.data?.exists);
  }, [allTokens]);

  const increaseRef = useRef<HTMLDivElement>(null);
  const moveLeftRef = useRef<boolean>(true);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (increaseRef.current) {
        if (increaseRef.current.scrollLeft === increaseRef.current.scrollWidth - increaseRef.current.clientWidth) {
          moveLeftRef.current = false;
        } else if (increaseRef.current.scrollLeft === 0) {
          moveLeftRef.current = true;
        }
        increaseRef.current.scrollTo(
          moveLeftRef.current ? increaseRef.current.scrollLeft + 1 : increaseRef.current.scrollLeft - 1,
          0,
        );
      }
    }, 20);

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  if (topPriceIncrease.length === 0 || !topPriceIncrease.some((entry) => entry.data)) {
    return null;
  }

  return (
    <Card my="16px">
      <ScrollableRow ref={increaseRef}>
        {topPriceIncrease.map((entry) => (
          entry.data ? <DataCard key={`top-card-token-${entry.data.address}`} tokenData={entry.data} /> : null
        ))}
      </ScrollableRow>
    </Card>
  );
};

export default TopTokenMovers;
