import clsx from "clsx";
import { memo } from "react";
// import { useTranslation } from "@pancakeswap/localization";
// import { escapeRegExp } from "@pancakeswap/utils/escapeRegExp";
import BigNumber from 'bignumber.js'
import { NumericFormat } from 'react-number-format';
import { formatDecimals } from "@pancakeswap/utils/formatBalance";
import { inputVariants } from "./SwapWidget.css";
// import { formatFixedNumber, getFullDisplayBalance } from "@pancakeswap/utils/formatBalance";
// const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`); // match escaped "." characters via in a non-capturing group

export const NumericalInput = memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  error,
  align,
  className,
  readOnly,
  ...rest
}: {
  value: string | number;
  onUserInput: (input: string) => void;
  error?: boolean;
  fontSize?: string;
  align?: "right" | "left";
  readonly?: boolean
} & Omit<React.HTMLProps<HTMLInputElement>, "ref" | "onChange" | "as">) {
  // const enforcer = (nextUserInput: string) => {
  //   if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {      
  //     onUserInput(nextUserInput);
  //   }
  // };

  // const { t } = useTranslation();

  // const Input = () => {
  //   return <input
  //     className={clsx(
  //       className,
  //       inputVariants({
  //         error,
  //         align,
  //       })
  //     )}
  //     {...rest}
  //     value={value}
  //     readOnly={true}
  //     // universal input options
  //     inputMode="decimal"
  //     autoComplete="off"
  //     autoCorrect="off"
  //     // text-specific options
  //     type="text"
  //     pattern="^[0-9]*[.,]?[0-9]*$"
  //     placeholder={placeholder || "0.0"}
  //     minLength={1}
  //     maxLength={79}
  //     spellCheck="false"
  //   />
  // }

  return (
    <NumericFormat 
      // customInput={Input}
      style={{width:'100%'}}
      thousandSeparator=","
      allowedDecimalSeparators={[',']}
      value={formatDecimals(value)}
      allowNegative={false}
      className={clsx(
        className,
        inputVariants({
          error,
          align,
        })
      )}
      readOnly={readOnly}
      placeholder={placeholder || "0.0"}
      onValueChange={(values, source) => {
        if(source.source==='event')
          onUserInput(values.value)
      }}
    />
    
  );
});
