import { useTranslation } from '@pancakeswap/localization';
import { Currency, CurrencyAmount, NATIVE, Percent, TradeType } from '@pancakeswap/sdk';
import {
  ArrowDownIcon,
  AutoColumn,
  AutoRenewIcon,
  Button,
  Box,
  Checkbox,
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  Message,
  MessageText,
  QuestionHelper,
  Swap as SwapUI,
  Text,
  LinkExternal,
} from '@pancakeswap/uikit';
import { useIsTransactionUnsupported } from 'hooks/Trades';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers';
import { useStableSwapByDefault } from 'state/user/smartRouter';
import { maxAmountSpend } from 'utils/maxAmountSpend';
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory';
import CurrencyInputPanel from 'components/CurrencyInputPanel';
import { AutoRow, RowBetween, RowFixed } from 'components/Layout/Row';
import { CommonBasesType } from 'components/SearchModal/types';
import { useCurrency } from 'hooks/Tokens';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback';
import { useAtomValue } from 'jotai';
import { Field } from 'state/swap/actions';
import { useDerivedSwapInfo, useSwapState } from 'state/swap/hooks';
import { useExpertModeManager, useUserSlippageTolerance } from 'state/user/hooks';
import { currencyId } from 'utils/currencyId';
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, formatExecutionPrice, formatExecutionPriceUnit } from 'utils/exchange';
import { BUYBACK_FEE, LP_HOLDERS_FEE, TOTAL_FEE, TREASURY_FEE } from 'config/constants/info';
import styled from 'styled-components';
import Link from 'next/link'
import { combinedTokenMapFromOfficialsUrlsAtom } from '../../../state/lists/hooks';
import AddressInputPanel from '../components/AddressInputPanel';
import CurrencyInputHeader from '../components/CurrencyInputHeader';
import { ArrowWrapper, StyledBalanceMaxMini, Wrapper } from '../components/styleds';
import SwapCommitButton from '../components/SwapCommitButton';
import useRefreshBlockNumberID from '../hooks/useRefreshBlockNumber';
import useWarningImport from '../hooks/useWarningImport';
import { MMAndAMMDealDisplay } from '../MMLinkPools/components/MMAndAMMDealDisplay';
import MMCommitButton from '../MMLinkPools/components/MMCommitButton';
import { parseMMError, shouldShowMMError } from '../MMLinkPools/utils/exchange';
import SmartSwapCommitButton from './components/SmartSwapCommitButton';
import { useDerivedSwapInfoWithStableSwap, useIsSmartRouterBetter, useTradeInfo } from './hooks';
import { MMError } from '../MMLinkPools/apis';
import { useDerivedSwapInfoWithMM } from '../MMLinkPools/hooks/useDerivedSwapInfoWithMM';
import FormattedPriceImpact from '../components/FormattedPriceImpact';


const TradeInfo = styled(AutoColumn)`
  margin-top: 8px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.default};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: ${({ theme }) => theme.colors.background};
`;

export const SmartSwapForm: React.FC<{ handleOutputSelect: (newCurrencyOutput: Currency) => void }> = ({
  handleOutputSelect,
}) => {
  const { t } = useTranslation();
  const { refreshBlockNumber } = useRefreshBlockNumberID();
  const warningSwapHandler = useWarningImport();
  const tokenMap = useAtomValue(combinedTokenMapFromOfficialsUrlsAtom);

  const [showMore, setShowMore] = useState(false);
  const { account, chainId } = useActiveWeb3React();
  const [isExpertMode] = useExpertModeManager();
  const [allowedSlippage] = useUserSlippageTolerance();
  const [allowUseSmartRouter, setAllowUseSmartRouter] = useState(false);
  const [isStableSwapByDefault] = useStableSwapByDefault();

  // swap state & price data

  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency ?? undefined,
      [Field.OUTPUT]: outputCurrency ?? undefined,
    }),
    [inputCurrency, outputCurrency],
  )

  const { v2Trade, inputError: swapInputError } = useDerivedSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
    recipient,
  )
  const {
    trade: tradeWithStableSwap,
    currencyBalances,
    parsedAmount,
    inputError: stableSwapInputError,
  } = useDerivedSwapInfoWithStableSwap(independentField, typedValue, inputCurrency, outputCurrency)

  const { mmTradeInfo, mmOrderBookTrade, mmRFQTrade, mmQuoteExpiryRemainingSec, isMMBetter } = useDerivedSwapInfoWithMM(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
    v2Trade,
    tradeWithStableSwap,
  )

  const isSmartRouterBetter = useIsSmartRouterBetter({ trade: tradeWithStableSwap, v2Trade })
  const tradeInfo = useTradeInfo({
    trade: tradeWithStableSwap,
    v2Trade,
    useSmartRouter: (allowUseSmartRouter || isStableSwapByDefault) && isSmartRouterBetter,
    allowedSlippage,
    chainId,
    swapInputError,
    stableSwapInputError,
  })

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE

  const parsedAmounts = showWrap
    ? {
      [Field.INPUT]: parsedAmount,
      [Field.OUTPUT]: parsedAmount,
    }
    : {
      [Field.INPUT]:
        independentField === Field.INPUT
          ? parsedAmount
          : isMMBetter
            ? mmTradeInfo.inputAmount
            : tradeInfo?.inputAmount,
      [Field.OUTPUT]:
        independentField === Field.OUTPUT
          ? parsedAmount
          : isMMBetter
            ? mmTradeInfo.outputAmount
            : tradeInfo?.outputAmount,
    }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()

  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(50) ?? '',
  }

  const amountToApprove = isMMBetter
    ? mmTradeInfo?.slippageAdjustedAmounts[Field.INPUT]
    : tradeInfo?.slippageAdjustedAmounts[Field.INPUT]
  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallback(
    amountToApprove,
    isMMBetter ? mmTradeInfo?.routerAddress : tradeInfo?.routerAddress,
  )

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.INPUT])

  const handleInputSelect = useCallback(
    (newCurrencyInput) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, newCurrencyInput)

      warningSwapHandler(newCurrencyInput)

      const newCurrencyInputId = currencyId(newCurrencyInput)
      if (newCurrencyInputId === outputCurrencyId) {
        replaceBrowserHistory('outputCurrency', inputCurrencyId)
      }
      replaceBrowserHistory('inputCurrency', newCurrencyInputId)
    },
    [inputCurrencyId, outputCurrencyId, onCurrencySelection, warningSwapHandler],
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const handlePercentInput = useCallback(
    (percent) => {
      if (maxAmountInput) {
        onUserInput(Field.INPUT, maxAmountInput.multiply(new Percent(percent, 100)).toExact())
      }
    },
    [maxAmountInput, onUserInput],
  )

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const hasAmount = Boolean(parsedAmount)

  const [showInverted, setShowInverted] = useState(false)

  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(v2Trade), [v2Trade])

  const totalFeePercent = `${(TOTAL_FEE * 100).toFixed(2)}%`
  const lpHoldersFeePercent = `${(LP_HOLDERS_FEE * 100).toFixed(2)}%`
  const treasuryFeePercent = `${(TREASURY_FEE * 100).toFixed(4)}%`
  const buyBackFeePercent = `${(BUYBACK_FEE * 100).toFixed(4)}%`

  const onRefreshPrice = useCallback(() => {
    if (hasAmount) {
      refreshBlockNumber()
    }
  }, [hasAmount, refreshBlockNumber])

  const smartRouterOn = !!tradeInfo && !tradeInfo.fallbackV2

  useEffect(() => {
    if (smartRouterOn && independentField === Field.OUTPUT && v2Trade) {
      onUserInput(Field.INPUT, v2Trade.inputAmount.toSignificant(6))
    }
  }, [smartRouterOn, independentField, onUserInput, v2Trade])

  useEffect(() => {
    setApprovalSubmitted(false)
  }, [smartRouterOn])

  const onUseSmartRouterChecked = useCallback(() => setAllowUseSmartRouter(!allowUseSmartRouter), [allowUseSmartRouter])

  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(v2Trade, allowedSlippage),
    [v2Trade, allowedSlippage],
  )

  const allowRecipient = isExpertMode && !showWrap && !smartRouterOn

  const BridgeContainer = styled.div<{
    chainId?: number;
  }>`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border-radius: 16px;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 16px;
  

`;

  //   background-color: ${({ chainId }) => (chainId === 1 ? "red" : "blue")}


  const BridgeLogo = styled.img`
  width: 32px;
  height: 32px;
    `


  return (
    <>
      <MMAndAMMDealDisplay
        independentField={independentField}
        isMMBetter={isMMBetter}
        tradeWithStableSwap={tradeWithStableSwap}
        v2Trade={v2Trade}
        mmTrade={mmTradeInfo?.trade || mmOrderBookTrade?.trade}
        mmQuoteExpiryRemainingSec={mmQuoteExpiryRemainingSec}
        errorMessage={
          mmRFQTrade?.error instanceof MMError
            ? mmRFQTrade?.error?.internalError
            : mmRFQTrade?.error?.message || mmOrderBookTrade?.inputError
        }
        rfqId={mmRFQTrade?.rfqId}
      />
      <CurrencyInputHeader
        title={t('Swap')}
        subtitle={t('Trade tokens in an instant')}
        hasAmount={hasAmount}
        onRefreshPrice={onRefreshPrice}
      />
      <Wrapper id="swap-page" style={{ /* minHeight: '380px' */ }}>
        <AutoColumn gap="sm">
          <CurrencyInputPanel
            label={independentField === Field.OUTPUT && !showWrap && tradeInfo ? t('From (estimated)') : t('From')}
            value={formattedAmounts[Field.INPUT]}
            showMaxButton
            maxAmount={maxAmountInput}
            showQuickInputButton
            currency={currencies[Field.INPUT]}
            onUserInput={handleTypeInput}
            onPercentInput={handlePercentInput}
            onMax={handleMaxInput}
            onCurrencySelect={handleInputSelect}
            otherCurrency={currencies[Field.OUTPUT]}
            id="swap-currency-input"
            showCommonBases
            showUSDPrice={!!tokenMap[chainId]?.[inputCurrencyId] || inputCurrencyId === NATIVE[chainId]?.symbol}
            commonBasesType={CommonBasesType.SWAP_LIMITORDER}
          />
          {/* {isAccessTokenSupported && inputCurrency?.isToken && (
            <Box>
              <AccessRisk token={inputCurrency} />
            </Box>
          )} */}

          <AutoColumn justify="space-between">
            <div
            // style={{
            //   height: '5px',
            //   marginTop: "-15px"
            // }}
            >
              <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0' }}>
                <SwapUI.SwitchButton
                  onClick={() => {
                    setApprovalSubmitted(false) // reset 2 step UI for approvals
                    onSwitchTokens()
                    replaceBrowserHistory('inputCurrency', outputCurrencyId)
                    replaceBrowserHistory('outputCurrency', inputCurrencyId)
                  }}
                />
                {allowRecipient && recipient === null ? (
                  <Button variant="text" id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                    {t('+ Add a recipient (optional)')}
                  </Button>
                ) : null}
              </AutoRow>
            </div>
          </AutoColumn>
          <CurrencyInputPanel
            value={formattedAmounts[Field.OUTPUT]}
            onUserInput={handleTypeOutput}
            label={independentField === Field.INPUT && !showWrap && tradeInfo ? t('To (estimated)') : t('To')}
            showMaxButton={false}
            currency={currencies[Field.OUTPUT]}
            onCurrencySelect={handleOutputSelect}
            otherCurrency={currencies[Field.INPUT]}
            id="swap-currency-output"
            showCommonBases

            showUSDPrice={!!tokenMap[chainId]?.[outputCurrencyId] || outputCurrencyId === NATIVE[chainId]?.symbol}
            commonBasesType={CommonBasesType.SWAP_LIMITORDER}
          />

          {
            priceImpactWithoutFee && Number(priceImpactWithoutFee.toFixed(2)) > 5 &&
            <div style={{ cursor: "pointer", height: "40px", alignItems: 'center', justifyContent: "space-around", display: "flex", flexDirection: "row", background: '#f76072', borderRadius: '8px', padding: '5px' }}>
              <div style={{ cursor: "pointer" }}><Link href="/dca">⚠ DCA can significantly soften your price impact</Link></div>
              <div><p>→</p></div>
            </div>
          }
          {/* disabled={smartRouterOn} */}
          {/* {isAccessTokenSupported && outputCurrency?.isToken && (
            <Box>
              <AccessRisk token={outputCurrency} />
            </Box>
          )} */}

          {isSmartRouterBetter && !isStableSwapByDefault && (
            <AutoColumn>
              {allowUseSmartRouter && (
                <Message variant="warning" mb="8px">
                  <MessageText>{t('This route includes StableSwap and can’t edit output')}</MessageText>
                </Message>
              )}
              <Flex alignItems="center" onClick={onUseSmartRouterChecked}>
                <Checkbox
                  scale="sm"
                  name="allowUseSmartRouter"
                  type="checkbox"
                  checked={allowUseSmartRouter}
                  onChange={onUseSmartRouterChecked}
                />
                <Text ml="8px" style={{ userSelect: 'none' }}>
                  {t('Use StableSwap for better fees')}
                </Text>
              </Flex>
            </AutoColumn>
          )}

          {allowRecipient && recipient !== null ? (
            <>
              <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable={false}>
                  <ArrowDownIcon width="16px" />
                </ArrowWrapper>
                <Button variant="text" id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                  {t('- Remove recipient')}
                </Button>
              </AutoRow>
              <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
            </>
          ) : null}
        </AutoColumn>
        {
          v2Trade ?
            <>
              <RowBetween align="center" onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer', marginTop: '14px' }}>
                {formatExecutionPriceUnit(v2Trade)}
                {showMore ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </RowBetween>
              {
                showMore &&
                <TradeInfo>
                  <RowBetween align="center">
                    <Text fontSize="14px">{t('Price')}</Text>
                    <Text
                      fontSize="14px"
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        textAlign: 'right',
                        paddingLeft: '10px',
                      }}
                    >
                      {formatExecutionPrice(v2Trade, showInverted)}
                      <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
                        <AutoRenewIcon width="14px" />
                      </StyledBalanceMaxMini>
                    </Text>
                  </RowBetween>

                  <RowBetween>
                    <RowFixed>
                      <Text fontSize="14px">
                        {v2Trade.tradeType === TradeType.EXACT_INPUT ? t('Minimum received') : t('Maximum sold')}
                      </Text>
                      <QuestionHelper
                        text={t(
                          'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
                        )}
                        ml="4px"
                        placement="top"
                      />
                    </RowFixed>
                    <RowFixed>
                      <Text fontSize="14px">
                        {v2Trade.tradeType === TradeType.EXACT_INPUT
                          ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                          : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
                      </Text>
                      <Text fontSize="14px" marginLeft="4px">
                        {v2Trade.tradeType === TradeType.EXACT_INPUT
                          ? v2Trade.outputAmount.currency.symbol
                          : v2Trade.inputAmount.currency.symbol}
                      </Text>
                    </RowFixed>
                  </RowBetween>
                  <RowBetween>
                    <RowFixed>
                      <Text fontSize="14px">{t('Price Impact')}</Text>
                      <QuestionHelper
                        text={t('The difference between the market price and your price due to trade size.')}
                        ml="4px"
                        placement="top"
                      />
                    </RowFixed>
                    <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
                  </RowBetween>
                  <RowBetween>
                    <RowFixed>
                      <Text fontSize="14px">{t('Liquidity Provider Fee')}</Text>
                      <QuestionHelper
                        text={
                          <>
                            <Text mb="12px">{t('For each trade a %amount% fee is paid', { amount: totalFeePercent })}</Text>
                            <Text>- {t('%amount% to LP token holders', { amount: lpHoldersFeePercent })}</Text>
                            <Text>- {t('%amount% towards 9INCH buyback and burn', { amount: treasuryFeePercent })}</Text>
                            <Text>- {t('%amount% towards BBC buyback and burn', { amount: buyBackFeePercent })}</Text>
                          </>
                        }
                        ml="4px"
                        placement="top"
                      />
                    </RowFixed>
                    <Text fontSize="14px">
                      {realizedLPFee ? `${realizedLPFee?.toSignificant(6)} ${v2Trade.inputAmount.currency.symbol}` : '-'}
                    </Text>
                  </RowBetween>
                </TradeInfo>
              }
            </>
            : null
        }

        <Box mt="1.25rem">
          {!tradeWithStableSwap &&
            !v2Trade &&
            mmOrderBookTrade?.inputError &&
            shouldShowMMError(mmOrderBookTrade?.inputError) ? (
            <Button width="100%" disabled style={{ textAlign: 'left', height: '3em' }}>
              {parseMMError(mmOrderBookTrade?.inputError)}
            </Button>
          ) : isMMBetter ? (
            <MMCommitButton
              swapIsUnsupported={swapIsUnsupported}
              account={account}
              showWrap={showWrap}
              wrapInputError={wrapInputError}
              onWrap={onWrap}
              wrapType={wrapType}
              approval={approval}
              approveCallback={approveCallback}
              approvalSubmitted={approvalSubmitted}
              currencies={currencies}
              isExpertMode={isExpertMode}
              rfqTrade={mmRFQTrade}
              swapInputError={mmOrderBookTrade?.inputError || parseMMError(mmRFQTrade?.error?.message)}
              currencyBalances={mmOrderBookTrade.currencyBalances}
              recipient={recipient}
              onUserInput={onUserInput}
              mmQuoteExpiryRemainingSec={mmQuoteExpiryRemainingSec}
            />
          ) : tradeInfo?.fallbackV2 ? (
            <SwapCommitButton
              swapIsUnsupported={swapIsUnsupported}
              account={account}
              showWrap={showWrap}
              wrapInputError={wrapInputError}
              onWrap={onWrap}
              wrapType={wrapType}
              parsedIndepentFieldAmount={parsedAmounts[independentField]}
              approval={approval}
              approveCallback={approveCallback}
              approvalSubmitted={approvalSubmitted}
              currencies={currencies}
              isExpertMode={isExpertMode}
              trade={v2Trade}
              swapInputError={tradeInfo.inputError}
              currencyBalances={currencyBalances}
              recipient={recipient}
              allowedSlippage={allowedSlippage}
              onUserInput={onUserInput}
            />
          ) : (
            <SmartSwapCommitButton
              swapIsUnsupported={swapIsUnsupported}
              account={account}
              showWrap={showWrap}
              wrapInputError={wrapInputError}
              onWrap={onWrap}
              wrapType={wrapType}
              parsedIndepentFieldAmount={parsedAmounts[independentField]}
              approval={approval}
              approveCallback={approveCallback}
              approvalSubmitted={approvalSubmitted}
              currencies={currencies}
              isExpertMode={isExpertMode}
              trade={tradeWithStableSwap}
              swapInputError={swapInputError}
              currencyBalances={currencyBalances}
              recipient={recipient}
              allowedSlippage={allowedSlippage}
              onUserInput={onUserInput}
            />
          )}
        </Box>

        <BridgeContainer>
          <div style={{ width: '32px', height: "32px" }}>
            <BridgeLogo src={chainId === 1 ? "/images/symbols/wpls.png" : "/images/symbols/eweth.png"} />
          </div>

          <div>
            <p>{chainId === 1 ? "Pulsechain token bridge" : "Ethereum token bridge"}</p>
          </div>
          <div>
            <LinkExternal href='https://bridge.9inch.io/#/bridge' bold={false} small />
          </div>
        </BridgeContainer>
      </Wrapper>
    </>
  )
}
