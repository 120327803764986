import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall, { multicallv3 } from 'utils/multicall'
import { getAddress, getMulticallAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import uniq from 'lodash/uniq'
import fromPairs from 'lodash/fromPairs'
import multiCallAbi from 'config/abi/Multicall.json'
import { findToken } from '@pancakeswap/tokens'

// Pool 0, Cake / Cake is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'WETH')
const bnbPools = [] // poolsConfig.filter((pool) => pool.stakingToken.symbol === 'WETH')
const nonMasterPools = [] // poolsConfig.filter((pool) => !pool.vaultKey)

export const fetchPoolsAllowance = async (account, chainId) => {
  const pools = nonBnbPools.filter(poolConfig => !!poolConfig.contractAddress[chainId])
  const calls = pools.map((pool) => ({
    address: findToken(pool.stakingToken.symbol, chainId).address,
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress, chainId)],
  }))
  const allowances = await multicall(erc20ABI, calls, chainId)
  return fromPairs(pools.map((pool, index) => [pool.sousId, new BigNumber(allowances[index]).toJSON()]))
}

export const fetchUserBalances = async (account, chainId) => {
  const pools = nonBnbPools.filter(poolConfig => !!poolConfig.contractAddress[chainId])
  const tokens = uniq(pools.map((pool) => findToken(pool.stakingToken.symbol, chainId)))
  const tokenBalanceCalls = tokens.map((token) => ({
    abi: erc20ABI,
    address: token.address,
    name: 'balanceOf',
    params: [account],
  }))
  const bnbBalanceCall = {
    abi: multiCallAbi,
    address: getMulticallAddress(chainId),
    name: 'getEthBalance',
    params: [account],
  }
  const tokenBnbBalancesRaw = await multicallv3({ calls: [...tokenBalanceCalls, bnbBalanceCall], chainId })
  const bnbBalance = tokenBnbBalancesRaw.pop()
  const tokenBalances = fromPairs(tokens.map((token, index) => [token.address, tokenBnbBalancesRaw[index]]))

  const poolTokenBalances = fromPairs(
    pools.map((pool) => {
      const stakingToken = findToken(pool.stakingToken.symbol, chainId)
      if (!tokenBalances[stakingToken.address]) return null
      return [pool.sousId, new BigNumber(tokenBalances[stakingToken.address]).toJSON()]
    }).filter(Boolean),
  )

  // BNB pools
  const bnbBalanceJson = new BigNumber(bnbBalance.toString()).toJSON()
  const bnbBalances = fromPairs(bnbPools.filter(poolConfig => !!poolConfig.contractAddress[chainId]).map((pool) => [pool.sousId, bnbBalanceJson]))
  return { ...poolTokenBalances, ...bnbBalances }
}

export const fetchUserStakeBalances = async (account, chainId) => {
  const pools = nonBnbPools.filter(pool => !!pool.contractAddress[chainId] && !pool.vaultKey)
  const calls = pools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(sousChefABI, calls, chainId)
  return fromPairs(
    pools.map((pool, index) => [pool.sousId, new BigNumber(userInfo[index].amount._hex).toJSON()]),
  )
}

export const fetchUserPendingRewards = async (account, chainId) => {
  const pools = nonBnbPools.filter(pool => !!pool.contractAddress[chainId] && !pool.vaultKey)
  const calls = pools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'pendingReward',
    params: [account],
  }))
  const res = await multicall(sousChefABI, calls, chainId)
  return fromPairs(pools.map((pool, index) => [pool.sousId, new BigNumber(res[index]).toJSON()]))
}
