import { ChainConfig, ChainId } from '@pancakeswap/sdk'
import { tokens } from '@pancakeswap/tokens'

export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  return [
    {
      pid: null,
      lpSymbol: 'ETH-DAI LP',
      lpAddress: ChainConfig[chainId]['ETH_DAI'],
      quoteToken: tokens[chainId].weth,
      token: tokens[chainId].dai,
      vaultPid: null,
    },
    {
      pid: null,
      lpSymbol: 'ETH-BBC LP',
      lpAddress: ChainConfig[chainId]['ETH_BBC'],
      quoteToken: tokens[chainId].weth,
      token: tokens[chainId].cake,
      vaultPid: null,
    },
    {
      pid: null,
      lpSymbol: '9INCH-DAI LP',
      lpAddress: ChainConfig[chainId]['9INCH_DAI'],
      quoteToken: tokens[chainId].dai,
      token: tokens[chainId].nineinch,
      vaultPid: null,
    },
    {
      pid: null,
      lpSymbol: '9INCH-USDC LP',
      lpAddress: ChainConfig[chainId]['9INCH_USDC'],
      quoteToken: tokens[chainId].usdc,
      token: tokens[chainId].nineinch,
      vaultPid: null,
    },
    {
      pid: null,
      lpSymbol: '9INCH-USDT LP',
      lpAddress: ChainConfig[chainId]['9INCH_USDT'],
      quoteToken: tokens[chainId].usdt,
      token: tokens[chainId].nineinch,
      vaultPid: null,
    },
    // {
    //   pid: null,
    //   lpSymbol: 'ETH-X LP',
    //   lpAddress: ChainConfig[chainId]['ETH_X'],
    //   quoteToken: tokens[chainId].weth,
    //   token: tokens[chainId].xtoken,
    //   vaultPid: null,
    // }
  ].filter(p => !!p.lpAddress).map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize }))
}
