import { Token, getTokenComparator } from '@pancakeswap/sdk'
import { useMemo } from 'react'
import { useAllTokenBalances } from '../../state/wallet/hooks'

// Priority list of tokens by their symbols
const priorityTokens = ['DAI', 'USDC', 'USDT', '9INCH', 'BBC', 'PP', 'PLD', 'WHETH', 'WIF'];

function useTokenComparator(inverted: boolean): (tokenA: Token, tokenB: Token) => number {
  const allBalances = useAllTokenBalances();

  // Original balance comparator
  const balanceComparator = useMemo(() => getTokenComparator(allBalances ?? {}), [allBalances]);

  // Enhanced comparator that considers balances first, then priority list
  const enhancedComparator = useMemo(() => {
    return (tokenA: Token, tokenB: Token): number => {
      // Check if either token has a balance above 0
      const balanceA = allBalances[tokenA.address]?.toSignificant(6);
      const balanceB = allBalances[tokenB.address]?.toSignificant(6);
      const hasBalanceA = balanceA && parseFloat(balanceA) > 0;
      const hasBalanceB = balanceB && parseFloat(balanceB) > 0;

      // If both have balances, compare by balance
      if (hasBalanceA && hasBalanceB) {
        return inverted ? -balanceComparator(tokenA, tokenB) : balanceComparator(tokenA, tokenB);
      }

      // If only one has a balance, it should come first
      if (hasBalanceA) return -1;
      if (hasBalanceB) return 1;

      // If neither has a balance, fallback to priority list
      const indexA = priorityTokens.indexOf(tokenA.symbol);
      const indexB = priorityTokens.indexOf(tokenB.symbol);

      if (indexA !== -1 && indexB === -1) {
        return -1; // A is prioritized over B
      } if (indexA === -1 && indexB !== -1) {
        return 1; // B is prioritized over A
      } if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB; // Both are prioritized, compare their positions
      }

      // If neither token is in the priority list, they are considered equal here
      return 0;
    };
  }, [inverted, balanceComparator, allBalances]);

  return enhancedComparator;
}

export default useTokenComparator
