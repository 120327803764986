import { BLOCKS_CLIENT, BLOCKS_CLIENT_ETH, BLOCKS_CLIENT_GOERLI, BLOCKS_CLIENT_TESTNET, BLOCKS_CLIENT_SEPOLIA, INFO_CLIENT, INFO_CLIENT_ETH, INFO_CLIENT_GOERLI, INFO_CLIENT_TESTNET, INFO_CLIENT_SEPOLIA, BLOCKS_CLIENT_PULSECHAIN, INFO_CLIENT_PULSECHAIN } from 'config/constants/endpoints'
import { infoClientETH, infoClient, infoClientGoerli, infoStableSwapClient, infoClientSepolia, infoClientLocalhost, infoClientPulsechain } from 'utils/graphql'

import { ChainId, ChainIdList } from '@pancakeswap/sdk'
import {
  ETH_TOKEN_BLACKLIST,
  PCS_ETH_START,
  PCS_V2_START,
  TOKEN_BLACKLIST,
  BSC_TOKEN_WHITELIST,
  ETH_TOKEN_WHITELIST,
  PCS_GOERLI_START,
  GOERLI_TOKEN_BLACKLIST,
  GOERLI_TOKEN_WHITELIST,
  PCS_SEPOLIA_START,
  PCS_MUMBAI_START,
  MUMBAI_TOKEN_BLACKLIST,
  SEPOLIA_TOKEN_BLACKLIST,
  SEPOLIA_TOKEN_WHITELIST,
  PULSECHAIN_TOKEN_BLACKLIST,
  PULSECHAIN_TOKEN_WHITELIST,
  PCS_PULSECHAIN_START,
} from 'config/constants/info'

export type MultiChainName = 'BSC' | 'ETH' | 'GOERLI' | 'SEPOLIA' | 'TESTNET' | 'PULSECHAIN'

export const multiChainQueryMainToken = {
  BSC: 'BNB',
  ETH: 'ETH',
  GOERLI: 'ETH',
  SEPOLIA: 'ETH',
  TESTNET: 'ETH',
  PULSECHAIN: 'PLS',
}

export const multiChainBlocksClient = {
  BSC: BLOCKS_CLIENT,
  ETH: BLOCKS_CLIENT_ETH,
  GOERLI: BLOCKS_CLIENT_GOERLI,
  SEPOLIA: BLOCKS_CLIENT_SEPOLIA,
  TESTNET: BLOCKS_CLIENT_TESTNET,
  PULSECHAIN: BLOCKS_CLIENT_PULSECHAIN,
}

export const multiChainStartTime = {
  BSC: PCS_V2_START,
  ETH: PCS_ETH_START,
  GOERLI: PCS_GOERLI_START,
  SEPOLIA: PCS_SEPOLIA_START,
  TESTNET: PCS_MUMBAI_START,
  PULSECHAIN: PCS_PULSECHAIN_START,
}

export const multiChainStartBlock = {
  BSC: 0,
  ETH: 18437760,
  GOERLI: 8694825,
  SEPOLIA: 3302219,
  TESTNET: 34290267,
  PULSECHAIN: 18672539,
}

export const multiChainBlockTime = {
  BSC: 1000,
  ETH: 1000,
  GOERLI: 1000,
  SEPOLIA: 1200,
  TESTNET: 1000,
  PULSECHAIN: 1000,
}

export const multiChainId = {
  BSC: ChainId.BSC,
  ETH: ChainId.ETHEREUM,
  GOERLI: ChainId.GOERLI,
  SEPOLIA: ChainId.SEPOLIA,
  TESTNET: ChainId.TESTNET,
  PULSECHAIN: ChainId.PULSECHAIN,
}

export const multiChainName = {
  [ChainId.BSC]: 'BSC',
  [ChainId.ETHEREUM]: 'ETH',
  [ChainId.GOERLI]: 'GERLI',
  [ChainId.SEPOLIA]: 'SEPOLIA',
  [ChainId.TESTNET]: 'TESTNET',
  [ChainId.PULSECHAIN]: 'PULSECHAIN',
}

export const multiChainPaths = {
  ...ChainIdList.reduce((prev, cur) => ({
    ...prev,
    [cur]: ''
  }), {}),
  [ChainId.ETHEREUM]: '/eth',
}

export const multiChainQueryClient = {
  BSC: infoClient,
  ETH: infoClientETH,
  GOERLI: infoClientGoerli,
  SEPOLIA: infoClientSepolia,
  TESTNET: infoClientLocalhost,
  PULSECHAIN: infoClientPulsechain,
}

export const multiChainQueryEndPoint = {
  BSC: INFO_CLIENT,
  ETH: INFO_CLIENT_ETH,
  GOERLI: INFO_CLIENT_GOERLI,
  SEPOLIA: INFO_CLIENT_SEPOLIA,
  TESTNET: INFO_CLIENT_TESTNET,
  PULSECHAIN: INFO_CLIENT_PULSECHAIN,
}

export const multiChainScan = {
  BSC: 'BscScan',
  ETH: 'EtherScan',
  SEPOLIA: 'EtherScan',
  PULSECHAIN: 'PulseScan',
}

export const multiChainTokenBlackList = {
  BSC: TOKEN_BLACKLIST,
  ETH: ETH_TOKEN_BLACKLIST,
  GOERLI: GOERLI_TOKEN_BLACKLIST,
  SEPOLIA: SEPOLIA_TOKEN_BLACKLIST,
  TESTNET: MUMBAI_TOKEN_BLACKLIST,
  PULSECHAIN: PULSECHAIN_TOKEN_BLACKLIST,
}

export const multiChainTokenWhiteList = {
  BSC: BSC_TOKEN_WHITELIST,
  ETH: ETH_TOKEN_WHITELIST,
  GOERLI: GOERLI_TOKEN_WHITELIST,
  SEPOLIA: SEPOLIA_TOKEN_WHITELIST,
  PULSECHAIN: PULSECHAIN_TOKEN_WHITELIST,
}

export const getMultiChainQueryEndPointWithStableSwap = (chainName: MultiChainName) => {
  const isStableSwap = checkIsStableSwap()
  if (isStableSwap) return infoStableSwapClient
  return multiChainQueryClient[chainName]
}

export const checkIsStableSwap = () => window.location.href.includes('stableSwap')
