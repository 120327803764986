import { useTranslation } from '@pancakeswap/localization'
import { NATIVE } from '@pancakeswap/sdk'
import {
  // ArrowDownIcon,
  // ArrowUpIcon,
  Box,
  // Button,
  // Flex,
  // InfoIcon,
  // Text,
  UserMenu,
  // UserMenuDivider,
  UserMenuItem,
  useTooltip,
} from '@pancakeswap/uikit'
import { useAccount } from 'wagmi'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useNetworkConnectorUpdater } from 'hooks/useActiveWeb3React'
// import { useHover } from 'hooks/useHover'
// import { useSessionChainId } from 'hooks/useSessionChainId'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { chains, CHAIN_IDS } from 'utils/wagmi'
// import Image from 'next/image'
import { ChainLogo } from './Logo/ChainLogo'

// const AptosChain = {
//   id: 1,
//   name: 'Aptos',
// }

const NetworkSelect = ({ switchNetwork, chainId }) => {
  // const { t } = useTranslation()

  const supportedChains = chains.filter((chain) => CHAIN_IDS.includes(chain.id))

  return (
    <>
      {/* <Box px="16px" py="8px">
        <Text color="textSubtle">{t('Select a Network')}</Text>
      </Box>
      <UserMenuDivider /> */}
      {supportedChains
        .map((chain) => (
          <UserMenuItem
            key={chain.id}
            style={{ justifyContent: 'flex-start' }}
            onClick={() => chain.id !== chainId && switchNetwork(chain.id)}
          >
            <ChainLogo chainId={chain.id} />
            <Box pl="12px" color={chain.id === chainId ? 'text' : 'textSubtl'}>
              {chain.name}
            </Box>
          </UserMenuItem>
        ))}
      {/* <UserMenuItem
        key={`aptos-${AptosChain.id}`}
        style={{ justifyContent: 'flex-start' }}
        as="a"
        target="_blank"
        href="https://aptos.pancakeswap.finance/swap"
      >
        <Image
          src="https://aptos.pancakeswap.finance/images/apt.png"
          width={24}
          height={24}
          unoptimized
          alt={`chain-aptos-${AptosChain.id}`}
        />{' '}
        <Text color="text" pl="12px">
          {AptosChain.name}
        </Text>
      </UserMenuItem> */}
    </>
  )
}

// const WrongNetworkSelect = ({ switchNetwork, chainId }) => {
//   const { t } = useTranslation()
//   const supportedChains = useMemo(
//     () => chains.filter((chain) => CHAIN_IDS.includes(chain.id)),
//     [chains],
//   )
//   const { targetRef, tooltip, tooltipVisible } = useTooltip(
//     t(
//       'The URL you are accessing (Chain id: %chainId%) belongs to %network%; mismatching your wallet’s network. Please switch the network to continue.',
//       {
//         chainId,
//         network: supportedChains.find((c) => c.id === chainId)?.name ?? 'Unknown network',
//       },
//     ),
//     {
//       placement: 'auto-start',
//       hideTimeout: 0,
//     },
//   )
//   const { chain } = useNetwork()
//   const localChainId = useLocalNetworkChain() || ChainDefaultId
//   const [, setSessionChainId] = useSessionChainId()

//   const localChainName = chains.find((c) => c.id === localChainId)?.name ?? 'BSC'

//   const [ref1, isHover] = useHover<HTMLButtonElement>()

//   return (
//     <>
//       <Flex ref={targetRef} alignItems="center" px="16px" py="8px">
//         <InfoIcon color="textSubtle" />
//         <Text color="textSubtle" pl="6px">
//           {t('Please switch network')}
//         </Text>
//       </Flex>
//       {tooltipVisible && tooltip}
//       <UserMenuDivider />
//       {chain && (
//         <UserMenuItem ref={ref1} onClick={() => setSessionChainId(chain.id)} style={{ justifyContent: 'flex-start' }}>
//           <ChainLogo chainId={chain.id} />
//           <Text color="secondary" bold pl="12px">
//             {chain.name}
//           </Text>
//         </UserMenuItem>
//       )}
//       <Box px="16px" pt="8px">
//         {isHover ? <ArrowUpIcon color="text" /> : <ArrowDownIcon color="text" />}
//       </Box>
//       <UserMenuItem onClick={() => switchNetwork(localChainId)} style={{ justifyContent: 'flex-start' }}>
//         <ChainLogo chainId={localChainId} />
//         <Text pl="12px">{localChainName}</Text>
//       </UserMenuItem>
//       <Button mx="16px" my="8px" scale="sm" onClick={() => switchNetwork(localChainId)}>
//         {t('Switch network in wallet')}
//       </Button>
//     </>
//   )
// }

export const NetworkSwitcher = () => {
  const { t } = useTranslation()
  const { chainId, isWrongNetwork } = useActiveChainId()
  const { pendingChainId, isLoading, canSwitch, switchNetworkAsync } = useSwitchNetwork()
  const router = useRouter()
  const { address: account } = useAccount()

  useNetworkConnectorUpdater()

  const foundChain = useMemo(
    () => chains.find((c) => c.id === (isLoading ? pendingChainId || chainId : chainId)),
    [isLoading, pendingChainId, chainId],
  )
  const symbol = NATIVE[foundChain?.id]?.symbol ?? foundChain?.nativeCurrency?.symbol
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('Unable to switch network. Please try it on your wallet'),
    { placement: 'bottom' },
  )

  const cannotChangeNetwork = !canSwitch

  if (!chainId || (!account && router.pathname.includes('info'))) {
    return null
  }

  if(isWrongNetwork)
    return null

  return (
    <Box height="50%" ref={cannotChangeNetwork ? targetRef : null}>
      {cannotChangeNetwork && tooltipVisible && tooltip}
      <UserMenu
        mr="8px"
        placement="bottom-start"
        variant={isLoading ? 'pending' : isWrongNetwork ? 'danger' : 'default'}
        avatarSrc={`/images/chains/${chainId}.png`}
        disabled={cannotChangeNetwork}
        text={
          isLoading ? (
            t('Requesting')
          ) : isWrongNetwork ? (
            t('Network')
          ) : foundChain ? (
            <>
              <Box display={['none', null, null, null, null, 'block']}>{foundChain.name}</Box>
              <Box display={['block', null, null, null, null, 'none']}>{symbol}</Box>
            </>
          ) : (
            t('Select a Network')
          )
        }
      >
        {({isOpen}) =>
          isOpen ? <NetworkSelect switchNetwork={switchNetworkAsync} chainId={chainId} /> : null
        }
      </UserMenu>
    </Box>
  )
}
