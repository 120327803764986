import { ChainId } from '@pancakeswap/sdk'

export const GRAPH_API_PROFILE = 'https://api.thegraph.com/subgraphs/name/pancakeswap/profile'
export const GRAPH_API_PREDICTION_BNB = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction-v2'
export const GRAPH_API_PREDICTION_CAKE = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction-cake'

export const GRAPH_API_LOTTERY = 'https://api.thegraph.com/subgraphs/name/pancakeswap/lottery'
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = 'https://profile.pancakeswap.com'
export const API_NFT = 'https://nft.pancakeswap.com/api/v1'
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const GRAPH_API_POTTERY = 'https://api.thegraph.com/subgraphs/name/pancakeswap/pottery'

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction'

export const INFO_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange'
export const INFO_CLIENT_ETH = 'https://node.9inch.io/subgraphs/name/exchange-ethereum'
// export const INFO_CLIENT_ETH = 'https://api.studio.thegraph.com/query/45659/9inch-exchange/version/latest'
export const INFO_CLIENT_GOERLI = 'https://api.thegraph.com/subgraphs/name/truth4you/exchange-goerli'
export const INFO_CLIENT_SEPOLIA = 'https://node.9inch.io/subgraphs/name/exchange'
export const INFO_CLIENT_TESTNET = 'https://api.thegraph.com/subgraphs/name/truth4you/exchange-testnet'
export const INFO_CLIENT_PULSECHAIN = 'https://node.9inch.io/subgraphs/name/exchange-pulsechain'

export const ORDERS_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange'
export const ORDERS_CLIENT_ETH = 'https://node.9inch.io/subgraphs/name/orders-ethereum'
// export const ORDERS_CLIENT_ETH = 'https://api.studio.thegraph.com/query/45659/9inch-orders/version/latest'
export const ORDERS_CLIENT_GOERLI = 'https://api.thegraph.com/subgraphs/name/truth4you/exchange-goerli'
export const ORDERS_CLIENT_SEPOLIA = 'https://api.studio.thegraph.com/query/45659/9inch-orders-sepolia/0.3'
export const ORDERS_CLIENT_TESTNET = 'https://api.thegraph.com/subgraphs/name/truth4you/exchange-mumbai'
export const ORDERS_CLIENT_PULSECHAIN = 'https://node.9inch.io/subgraphs/name/orders-pulsechain'

export const DCA_ORDERS_CLIENT_ETH = 'https://node.9inch.io/subgraphs/name/dca-ethereum'
export const DCA_ORDERS_CLIENT_PULSECHAIN = 'https://node.9inch.io/subgraphs/name/dca2-pulsechain'
// export const DCA_ORDERS_CLIENT_PULSECHAIN = 'https://node.9inch.io/subgraphs/name/dca-pulsechaintestnet'

export const BLOCKS_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'
export const BLOCKS_CLIENT_ETH = 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks'
export const BLOCKS_CLIENT_GOERLI = 'https://api.thegraph.com/subgraphs/name/blocklytics/goerli-blocks'
export const BLOCKS_CLIENT_SEPOLIA = 'https://node.9inch.io/subgraphs/name/blocks-sepolia'
// export const BLOCKS_CLIENT_SEPOLIA = 'https://api.studio.thegraph.com/query/45659/blocks-sepolia/version/latest'
export const BLOCKS_CLIENT_TESTNET = 'https://api.thegraph.com/subgraphs/name/andreinnguyen/mumbai-block'
export const BLOCKS_CLIENT_PULSECHAIN = 'https://graph.pulsechain.com/subgraphs/name/pulsechain/blocks'
// export const BLOCKS_CLIENT_PULSECHAIN = 'https://node.9inch.io/subgraphs/name/blocks-pulsechaintest'

export const STABLESWAP_SUBGRAPH_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-stableswap'
export const GRAPH_API_NFTMARKET = 'https://api.thegraph.com/subgraphs/name/pancakeswap/nft-market'
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/graphql'

export const TC_MOBOX_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v3'
export const TC_MOD_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v4'

export const FARM_API = 'https://farms-api.pancakeswap.com'

export const BIT_QUERY = 'https://graphql.bitquery.io'

export const ACCESS_RISK_API = '/api/risk'

export const CELER_API = 'https://api.celerscan.com/scan'

export const INFO_CLIENT_WITH_CHAIN = {
  [ChainId.BSC]: INFO_CLIENT,
  [ChainId.ETHEREUM]: INFO_CLIENT_ETH,
  [ChainId.GOERLI]: INFO_CLIENT_GOERLI,
  [ChainId.SEPOLIA]: INFO_CLIENT_SEPOLIA,
  [ChainId.TESTNET]: INFO_CLIENT_TESTNET,
  [ChainId.PULSECHAIN]: INFO_CLIENT_PULSECHAIN,
}

export const ORDERS_CLIENT_WITH_CHAIN = {
  [ChainId.BSC]: ORDERS_CLIENT,
  [ChainId.ETHEREUM]: ORDERS_CLIENT_ETH,
  [ChainId.GOERLI]: ORDERS_CLIENT_GOERLI,
  [ChainId.SEPOLIA]: ORDERS_CLIENT_SEPOLIA,
  [ChainId.TESTNET]: ORDERS_CLIENT_TESTNET,
  [ChainId.PULSECHAIN]: ORDERS_CLIENT_PULSECHAIN,
}

export const DCA_ORDERS_CLIENT_WITH_CHAIN = {
  [ChainId.BSC]: ORDERS_CLIENT,
  [ChainId.ETHEREUM]: DCA_ORDERS_CLIENT_ETH,
  [ChainId.GOERLI]: ORDERS_CLIENT_GOERLI,
  [ChainId.SEPOLIA]: ORDERS_CLIENT_SEPOLIA,
  [ChainId.TESTNET]: ORDERS_CLIENT_TESTNET,
  [ChainId.PULSECHAIN]: DCA_ORDERS_CLIENT_PULSECHAIN,
  [ChainId.PULSECHAINV4]: DCA_ORDERS_CLIENT_PULSECHAIN,
}

export const BLOCKS_CLIENT_WITH_CHAIN = {
  [ChainId.BSC]: BLOCKS_CLIENT,
  [ChainId.ETHEREUM]: BLOCKS_CLIENT_ETH,
  [ChainId.GOERLI]: BLOCKS_CLIENT_GOERLI,
  [ChainId.SEPOLIA]: BLOCKS_CLIENT_SEPOLIA,
  [ChainId.TESTNET]: BLOCKS_CLIENT_TESTNET,
  [ChainId.PULSECHAIN]: BLOCKS_CLIENT_PULSECHAIN,
}

export const ASSET_CDN = 'https://tacofy-swap.web.app'
