// import styled from 'styled-components'
import { CHAIN_IDS } from 'utils/wagmi'
import Swap from '../views/Swap'
import { SwapFeaturesProvider } from '../views/Swap/SwapFeaturesContext'

// const MobileView = styled.div`
//   @media (max-width: 767px) {
//       width:100%;
//       position:absolute;
//     & {
//       // display: none;

//       height:100%;
//     }
//   }
// `
const SwapPage = () => {

  return (

    <SwapFeaturesProvider>
      <Swap />
    </SwapFeaturesProvider>

  )
}

SwapPage.chains = CHAIN_IDS

export default SwapPage
