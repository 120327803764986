import { getFarmsPriceHelperLpFiles } from '@pancakeswap/farms/constants/priceHelperLps/getFarmsPriceHelperLpFiles'
import { ChainConfig, ChainId } from '@pancakeswap/sdk'
import { tokens } from '@pancakeswap/tokens'

export { getFarmsPriceHelperLpFiles }

export const getPoolsPriceHelperLpFiles = (chainId: ChainId) => {
  return [
    {
      pid: null,
      lpSymbol: 'WETH-USDC LP',
      lpAddress: ChainConfig[chainId].USDC_WETH,
      quoteToken: tokens[chainId].usdc,
      token: tokens[chainId].weth,
      vaultPid: null,
    },
  ].map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize }))
}
