import {
  ChainId,
  Currency,
  CurrencyAmount,
  Pair,
  Price,
  Token,
  WNATIVE,
  ERC20Token,
  WETH9,
} from '@pancakeswap/sdk'
import { FAST_INTERVAL } from 'config/constants'
import { BUSD, CAKE, DAI, NINEINCH, USDC, USDT } from '@pancakeswap/tokens'
import { useMemo } from 'react'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import getLpAddress from 'utils/getLpAddress'
import { multiChainName } from 'state/info/constant'
import { fetchDerivedUSDPricePerAddress } from 'state/info/queries/pools/poolData'
import { multiplyPriceByAmount } from 'utils/prices'
import { useProvider } from 'wagmi'
import { usePairContract } from './useContract'
import { PairState, usePairs } from './usePairs'
import { useActiveChainId } from './useActiveChainId'
import useActiveWeb3React from './useActiveWeb3React'

// export default function useBUSDPrice(currency?: Currency): Price<Currency, Currency> | undefined {
//   const { chainId } = useActiveChainId()
//   const wrapped = currency?.wrapped
//   const wnative = WNATIVE[chainId]
//   // const stable = DAI[chainId] || USDC[chainId]
//   const stable = chainId===ChainId.SEPOLIA ? USDC[chainId] : DAI[chainId]
//   const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
//     () => [
//       [chainId && wrapped && wnative?.equals(wrapped) ? undefined : currency, chainId ? wnative : undefined],
//       [stable && wrapped?.equals(stable) ? undefined : wrapped, stable],
//       [chainId ? wnative : undefined, stable],
//     ],
//     [wnative, stable, chainId, currency, wrapped],
//   )
//   const [[bnbPairState, bnbPair], [busdPairState, busdPair], [busdBnbPairState, busdBnbPair]] = usePairs(tokenPairs)

//   return useMemo(() => {
//     if (!currency || !wrapped || !chainId || !wnative) {
//       return undefined
//     }

//     // handle busd
//     if (wrapped.equals(stable)) {
//       return new Price(stable, stable, '1', '1')
//     }

//     const isBUSDPairExist =
//       busdPair &&
//       busdPairState === PairState.EXISTS &&
//       busdPair.reserve0.greaterThan('0') &&
//       busdPair.reserve1.greaterThan('0')

//     // handle wbnb/bnb
//     if (wrapped.equals(wnative)) {
//       if (isBUSDPairExist) {
//         const price = busdPair.priceOf(wnative)
//         return new Price(currency, stable, price.denominator, price.numerator)
//       }
//       return undefined
//     }

//     const isBnbPairExist =
//       bnbPair &&
//       bnbPairState === PairState.EXISTS &&
//       bnbPair.reserve0.greaterThan('0') &&
//       bnbPair.reserve1.greaterThan('0')
//     const isBusdBnbPairExist =
//       busdBnbPair &&
//       busdBnbPairState === PairState.EXISTS &&
//       busdBnbPair.reserve0.greaterThan('0') &&
//       busdBnbPair.reserve1.greaterThan('0')

//     const bnbPairBNBAmount = isBnbPairExist && bnbPair?.reserveOf(wnative)
//     const bnbPairBNBBUSDValue: JSBI =
//       bnbPairBNBAmount && isBUSDPairExist && isBusdBnbPairExist
//         ? busdBnbPair.priceOf(wnative).quote(bnbPairBNBAmount).quotient
//         : JSBI.BigInt(0)

//     // all other tokens
//     // first try the busd pair
//     if (isBUSDPairExist && busdPair.reserveOf(stable).greaterThan(bnbPairBNBBUSDValue)) {
//       const price = busdPair.priceOf(wrapped)
//       return new Price(currency, stable, price.denominator, price.numerator)
//     }
//     if (isBnbPairExist && isBusdBnbPairExist) {
//       if (busdBnbPair.reserveOf(stable).greaterThan('0') && bnbPair.reserveOf(wnative).greaterThan('0')) {
//         const bnbBusdPrice = busdBnbPair.priceOf(stable)
//         const currencyBnbPrice = bnbPair.priceOf(wnative)
//         const busdPrice = bnbBusdPrice.multiply(currencyBnbPrice).invert()
//         return new Price(currency, stable, busdPrice.denominator, busdPrice.numerator)
//       }
//     }

//     return undefined
//   }, [
//     currency,
//     wrapped,
//     chainId,
//     wnative,
//     stable,
//     bnbPair,
//     busdBnbPair,
//     busdPairState,
//     busdPair,
//     bnbPairState,
//     busdBnbPairState,
//   ])
// }

/**
 * Returns the price in BUSD of the input currency
 * @param currency currency to compute the BUSD price of
 */
export default function useBUSDPrice(currency?: Currency): Price<Currency, Currency> | undefined {
  const { chainId } = useActiveChainId()
  const wrapped = currency?.wrapped
  const common = NINEINCH[chainId] as Currency
  // const wnative = WNATIVE[chainId]
  // const stable = DAI[chainId] || USDC[chainId]
  // const stable = USDC[chainId] ?? DAI[chainId] ?? USDT[chainId]
  const stables = [USDC[chainId] as Currency, DAI[chainId] as Currency, USDT[chainId] as Currency].filter(token => !!token)
  const stablePairs: [Currency | undefined, Currency | undefined][] = stables.map(stable => [common, stable])
  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [common, currency],
      ...stablePairs
      // [chainId && wrapped && wnative?.equals(wrapped) ? undefined : currency, chainId ? wnative : undefined],
      // [stables[0] && wrapped?.equals(stables[0]) ? undefined : wrapped, stables[0]],
      // [chainId ? wnative : undefined, stables[0]],
    ],
    [stablePairs, currency, common],
  )
  // const [[bnbPairState, bnbPair], [busdPairState, busdPair], [busdBnbPairState, busdBnbPair]] = usePairs(tokenPairs)
  const [[commonPairState, commonPair], ...stablePairStates] = usePairs(tokenPairs)

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined
    }

    const stable = stables.find(token => wrapped.equals(token))

    // handle busd
    if (stable) {
      return new Price(stable, stable, '1', '1')
    }

    // let reserve = new Fraction(0)
    let priceMax
    let priceMin
    for(const [stablePairState, stablePair] of stablePairStates) {
      if(stablePair && 
        stablePairState===PairState.EXISTS && 
        stablePair.reserve0.greaterThan('0') &&
        stablePair.reserve1.greaterThan('0')
      ) {
        const price = stablePair.priceOf(common.wrapped)
        if(!priceMax || price.greaterThan(priceMax))
          priceMax = price
        if(!priceMin || price.lessThan(priceMin))
          priceMin = price
        // if(stablePair.token0.equals(common)) {
        //   reserve = reserve.add(stablePair.reserve0.asFraction)
        //   priceSum = priceSum.add(new Fraction(price.toSignificant(18)).multiply(stablePair.reserve0.asFraction))
        // } else {
        //   reserve = reserve.add(stablePair.reserve1.asFraction)
        //   priceSum = priceSum.add(new Fraction(price.toSignificant(18)).multiply(stablePair.reserve1.asFraction))
        // }
        // console.log(stablePair.token0.symbol, stablePair.token1.symbol, price.toFixed(), reserve.toSignificant(18))
      }
    }
    // console.log('total', priceSum.toFixed(18), reserve.toSignificant(18))
    // const result = priceSum.divide(reserve)
    // const commonPrice = new Price(common, stables[1], result.denominator, result.numerator)

    if (wrapped.equals(common))
      return priceMax

    if(commonPair && 
      commonPairState===PairState.EXISTS && 
      commonPair.reserve0.greaterThan('0') &&
      commonPair.reserve1.greaterThan('0') &&
      priceMax
    ) {
      const price = commonPair.priceOf(wrapped)
      const currencyPrice = price?.multiply(priceMax)
      return currencyPrice
    }
    /*
    const isBUSDPairExist =
      busdPair &&
      busdPairState === PairState.EXISTS &&
      busdPair.reserve0.greaterThan('0') &&
      busdPair.reserve1.greaterThan('0')

    // handle wbnb/bnb
    if (wrapped.equals(wnative)) {
      if (isBUSDPairExist) {
        const price = busdPair.priceOf(wnative)
        return new Price(currency, stable, price.denominator, price.numerator)
      }
      return undefined
    }

    const isBnbPairExist =
      bnbPair &&
      bnbPairState === PairState.EXISTS &&
      bnbPair.reserve0.greaterThan('0') &&
      bnbPair.reserve1.greaterThan('0')
    const isBusdBnbPairExist =
      busdBnbPair &&
      busdBnbPairState === PairState.EXISTS &&
      busdBnbPair.reserve0.greaterThan('0') &&
      busdBnbPair.reserve1.greaterThan('0')

    const bnbPairBNBAmount = isBnbPairExist && bnbPair?.reserveOf(wnative)
    const bnbPairBNBBUSDValue: JSBI =
      bnbPairBNBAmount && isBUSDPairExist && isBusdBnbPairExist
        ? busdBnbPair.priceOf(wnative).quote(bnbPairBNBAmount).quotient
        : JSBI.BigInt(0)

    // all other tokens
    // first try the busd pair
    if (isBUSDPairExist && busdPair.reserveOf(stable).greaterThan(bnbPairBNBBUSDValue)) {
      const price = busdPair.priceOf(wrapped)
      return new Price(currency, stable, price.denominator, price.numerator)
    }
    if (isBnbPairExist && isBusdBnbPairExist) {
      if (busdBnbPair.reserveOf(stable).greaterThan('0') && bnbPair.reserveOf(wnative).greaterThan('0')) {
        const bnbBusdPrice = busdBnbPair.priceOf(stable)
        const currencyBnbPrice = bnbPair.priceOf(wnative)
        const busdPrice = bnbBusdPrice.multiply(currencyBnbPrice).invert()
        return new Price(currency, stable, busdPrice.denominator, busdPrice.numerator)
      }
    } */

    return undefined
  }, [
    currency,
    wrapped,
    common,
    chainId,
    // wnative,
    commonPair,
    commonPairState,
    stablePairStates,
    stables,
    // bnbPair,
    // busdBnbPair,
    // busdPairState,
    // busdPair,
    // bnbPairState,
    // busdBnbPairState,
  ])
}

export const usePriceByPairs = (currencyA?: Currency, currencyB?: Currency) => {
  const { chainId } = useActiveWeb3React()
  const [tokenA, tokenB] = [currencyA?.wrapped, currencyB?.wrapped]
  const pairAddress = getLpAddress(tokenA, tokenB)
  const pairContract = usePairContract(pairAddress)
  const provider = useProvider({ chainId })

  const { data: price } = useSWR(
    currencyA && currencyB && ['pair-price', currencyA, currencyB],
    async () => {
      const reserves = await pairContract.connect(provider).getReserves()
      if (!reserves) {
        return null
      }
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

      const pair = new Pair(
        CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
        CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
      )

      return pair.priceOf(tokenB)
    },
    { dedupingInterval: FAST_INTERVAL, refreshInterval: FAST_INTERVAL },
  )

  return price
}

export const useBUSDCurrencyAmount = (currency?: Currency, amount?: number): number | undefined => {
  const { data } = useSWRImmutable(
    amount && ['fiat-price-usd', currency],
    async () => {
      const address = currency.isToken ? currency.address : WETH9[currency.chainId].address
      try {
        const result = await fetchDerivedUSDPricePerAddress(address.toLowerCase(), multiChainName[currency.chainId]);
        if (result.error) {
          throw new Error(`Error fetching price for address ${address.toLowerCase()}`);
        }
        return result.price;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    {
      dedupingInterval: 30_000,
      refreshInterval: 30_000,
    },
  )

  if (amount) {
    if (data) {
      return data * amount
    }
  }
  return undefined
}

export const useBUSDCakeAmount = (amount: number): number | undefined => {
  const cakeBusdPrice = useCakeBusdPrice()
  const [price1, price2] = useCakeBusdPrice2()
  if (cakeBusdPrice) {
    return multiplyPriceByAmount(cakeBusdPrice, amount)
  }
  if(price1 && price2)
    return multiplyPriceByAmount(price1, multiplyPriceByAmount(price2, amount))
  return undefined
}

// @Note: only fetch from one pair
export const useCakeBusdPrice = (): Price<ERC20Token, ERC20Token> | undefined => {
  const { chainId } = useActiveChainId()
  const price = useBUSDPrice(CAKE[chainId])
  return price ? price as Price<ERC20Token, ERC20Token> : undefined
  // const isTestnet = !forceMainnet && isChainTestnet(chainId)
  // Return bsc testnet cake if chain is testnet
  // const cake: Token = CAKE[chainId]
  // const price = usePriceByPairs(BUSD[chainId] || DAI[chainId] || USDC[chainId], cake)
  // return price
}

export const useCakeBusdPrice2 = (): Price<ERC20Token, ERC20Token>[] | undefined => {
  const { chainId } = useActiveChainId()
  // const isTestnet = !forceMainnet && isChainTestnet(chainId)
  // Return bsc testnet cake if chain is testnet
  const cake: Token = CAKE[chainId]
  const price1 = usePriceByPairs(BUSD[chainId] || DAI[chainId] || USDC[chainId], WNATIVE[chainId])
  const price2 = usePriceByPairs(WNATIVE[chainId], cake)
  return [price1, price2]
}

export const useTokenBusdPrice = (token: ERC20Token): number => {
  const { chainId } = useActiveChainId()
  const price1 = usePriceByPairs(DAI[chainId], WNATIVE[chainId])
  const price2 = usePriceByPairs(WNATIVE[chainId], token)
  if(price1 && price2)
    return multiplyPriceByAmount(price1, multiplyPriceByAmount(price2, 1))
  return 0
}

// @Note: only fetch from one pair
export const useBNBBusdPrice = (): Price<ERC20Token, ERC20Token> | undefined => {
  const { chainId } = useActiveChainId()
  // const isTestnet = !forceMainnet && isChainTestnet(chainId)
  // Return bsc testnet wbnb if chain is testnet
  // const wbnb: Token = isTestnet ? WNATIVE[ChainId.PULSECHAIN] : WNATIVE[ChainId.BSC]
  return usePriceByPairs(BUSD[chainId], WNATIVE[chainId])
}
